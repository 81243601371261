import React from "react";
import { IoSpeedometer, IoPerson, IoDocumentText } from "react-icons/io5";

const StatisticCard = ({ imgSrc, altText, statText, statNumber, iconType }) => {
  let Icon;
  switch (iconType) {
    case "speedometer":
      Icon = IoSpeedometer;
      break;
    case "person":
      Icon = IoPerson;
      break;
    case "document":
      Icon = IoDocumentText;
      break;
    default:
      Icon = IoDocumentText;
  }
  return (
    <div className="bg-gradient-to-r from-[#152236] to-[#345e9e] p-10 px-14 rounded-2xl flex gap-10 " >
      <Icon size={60} />
      <div>
        <div className=" text-3xl font-semibold">{statNumber}</div>
        <div className=" text-2xl">{statText}</div>
      </div>
    </div>
  );
};

export default StatisticCard;
