import styled from "styled-components";
import { Typography } from "antd";

const { Title } = Typography;

export const PropertyDetails = styled.div`
  position: relative;

  .swiper {
    width: 600px;
    padding-bottom: 50px;
    background: white;
    border-radius: 20px;

    .swiper-button-prev,
    .swiper-button-next {
      color: #38caff;
    }

    .swiper-pagination-bullet-active {
      background: #38caff;
    }

    img {
      width: 100%;
    }
  }

  .result-home {
    min-height: 290px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
    position: relative;

    &:hover {
      transform: scale(1.01);
      transition: all 0.2s ease;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 9px 0px;
    }

    &-favorite {
      position: absolute;
      right: 10px;
      top: 10px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-image {
      width: 100%;
      img {
        width: 100%;
      }
    }

    &-info {
      padding: 8px;
      &-bottom {
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &-top {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        &-price {
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 8px;
        }

        &-rooms {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 10px;
        }

        &-bed {
          margin-right: 8px;
        }

        &-bed,
        &-bath {
          font-size: 20px;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1240px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .property-right {
      width: 100%;
    }
    .property-left {
      width: 100%;
    }

    .swiper {
      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .swiper {
      width: 100%;
    }
  }
`;

export const PropertyValueWrapper = styled.div`
  display: flex;
  align-items: ${(props) => (props.normal ? "flex-start" : "center")};
  justify-content: flex-start;
  gap: 8px;
  margin: ${(props) => (props.normal ? "0px 0px" : "10px 0px")};
  width: 100%;
`;

export const SaveCountsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const OverviewDetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    justify-content: flex-start;

    /* .overview-left,
    .overview-right {
      width: 100%;
    } */
  }
`;

export const OverviewWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
export const ShowMoreWrapper = styled.div`
  display: flex;
  width: 600px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: auto;
  margin-top: 20px;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const CustomTitle = styled(Title)`
  height: ${(props) => props.size};
  overflow: hidden;
`;

export const SchoolWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SchoolRate = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #0d4599;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: white;
  margin-right: 10px;

  span {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const SchoolInfo = styled.div`
  position: relative;
`;

export const SchoolLink = styled.div`
  a {
    color: #006aff;
    text-decoration: none;
  }
`;

export const SpinWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const InteriorValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const SwiperWrapper = styled.div`
  position: relative;

  .favorite-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
