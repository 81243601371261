import React from "react";
import ButtonStandard from "./ButtonStandard";

const PriceCard = ({
  planName,
  description,
  services,
  monthlyPrice,
  yearlyPrice,
  buttonHref,
  headerColor,
  subPrice,
  monthly,
}) => {
  return (
    <div className="bg-houndWhite rounded-lg shadow-lg shadow-[#4B4B4B] p-2 items-center justify-between flex flex-col lg:w-3/5 mx-auto">
      <div className="bg-gradient-to-b from-houndBlue to-houndBlueD4 text-white text-center rounded-t-lg py-2 h-40 justify-center content-center  w-full">
        <p className="text-3xl font-bold">Get Started</p>
      </div>
      <div className=" py-20 text-center gap-12 lg:gap-20 flex flex-col lg:flex-row">
        <div className="flex gap-10 flex-col">
          <div className="text-center text-4xl font-light">$39.99/Month</div>
          <ButtonStandard
            href={buttonHref}
            text={"Choose Monthly"}
            width="w-1/2"
            mdtext={"text-lg"}
            px="px-20"
            py="py-4"
          />
          <span className="lg:hidden">or</span>
        </div>
        <div className="flex gap-10 flex-col">
          <div className="text-center text-4xl font-light">$399.99/Year</div>
          <ButtonStandard
            href={buttonHref}
            text={"Choose Yearly"}
            width="w-1/2"
            mdtext={"text-lg"}
            px="px-20"
            py="py-4"
          />
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
