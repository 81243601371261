import React, { useState } from "react";
import { FaSearch, FaBars } from "react-icons/fa";
import HHLogo from "../../assets/HHLogos/HHLogo.png"; // Adjust the path as necessary
import Logo from "./Logo";
import MobileToolbar from "./MobileToolbar";

const HeaderMay2024 = ({ isMobile, searchBar = false }) => {
  const [isMobileToolbarOpen, setIsMobileToolbarOpen] = useState(false);

  const handleMobileToolbarToggle = () => {
    setIsMobileToolbarOpen(!isMobileToolbarOpen);
  };

  return (
    <div className="flex flex-row items-center justify-between px-12 py-6 lg:sticky w-full z-[100]">
      <Logo />
      {isMobile ? (
        <button
          onClick={handleMobileToolbarToggle}
          className="text-white text-2xl bg-transparent border-0"
        >
          <FaBars />
        </button>
      ) : (
        <div className="flex flex-row gap-10">
          <nav className="flex flex-col lg:flex-row items-center space-x-10 text-xl">
            <a href="/main/home" className="text-white">
              Home
            </a>
            <a href="/main/dashboard" className="text-white">
              Chatsearch
            </a>
            <a href="/main/search" className="text-white">
              Mapsearch
            </a>
            <a href="https://hh.househound.ai/login" className="text-white">
              Login
            </a>
          </nav>
          {searchBar && (
            <div className="relative">
              <input
                type="text"
                placeholder="What are you looking for?"
                className="pl-10 pr-4 py-3 w-72 rounded-full bg-white text-black"
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-houndBlueD3" />
            </div>
          )}
        </div>
      )}
      <MobileToolbar
        isOpen={isMobileToolbarOpen}
        onClose={handleMobileToolbarToggle}
      />
    </div>
  );
};

export default HeaderMay2024;
