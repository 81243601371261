import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  updated: null,
  error: null,
  savedHomes: [],
};

const isLoading = (action) => {
  return [
    "favorite/saveHome/pending",
    "favorite/removeHome/pending",
    "favorite/loadHomes/pending",
  ].includes(action.type);
};

const isFinishLoading = (action) => {
  return [
    "favorite/saveHome/fulfilled",
    "favorite/saveHome/rejected",
    "favorite/removeHome/fulfilled",
    "favorite/removeHome/rejected",
    "favorite/loadHome/fulfilled",
    "favorite/loadHome/rejected",
  ].includes(action.type);
};

const isRejected = (action) => {
  return [
    "favorite/saveHome/rejected",
    "favorite/removeHome/rejected",
    "favorite/loadHomes/rejected",
  ].includes(action.type);
};

const addOrRemoved = (action) => {
  return [
    "favorite/saveHome/fulfilled",
    "favorite/removeHome/fulfilled",
  ].includes(action.type);
};

export const saveHome = createAsyncThunk(
  "favorite/saveHome",
  async (values, { rejectWithValue }) => {
    try {
      return values;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const updateHome = createAsyncThunk(
  "favorite/updateHome",
  async (values, { rejectWithValue }) => {
    try {
      return values;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const loadHomes = createAsyncThunk(
  "favorite/loadHomes",
  async (values, { rejectWithValue }) => {
    try {
      return values.savedHomes;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const removeHome = createAsyncThunk(
  "favorite/removeHome",
  async (values, { rejectWithValue }) => {
    try {
      return values;
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(saveHome.fulfilled, (state, action) => {
        const newValue = action.payload;
        state.savedHomes.push(newValue);
      })
      .addCase(loadHomes.fulfilled, (state, action) => {
        state.savedHomes = action.payload;
      })
      .addCase(updateHome.fulfilled, (state, action) => {
        state.updated = action.payload;
      })
      .addCase(removeHome.fulfilled, (state, action) => {
        const newValue = action.payload;
        const index = state.savedHomes.findIndex((item) => item === newValue);
        if (index > -1) {
          // only splice array when item is found
          state.savedHomes.splice(index, 1); // 2nd parameter means remove one item only
        }
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addMatcher(addOrRemoved, (state, action) => {
        state.updated = true;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default favoriteSlice.reducer;
