import { useContext, useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

import {
  ChatbotWrapper,
  DashboardPage,
  DashboardWrapper,
  SavedSearchWrapper,
  SpinWrapper,
  StyledFloatButton,
  StyledTabs,
} from "./styles";
import SavedProperties from "./SavedProperties";
import InfoSection from "./InfoSection";
import { ReactComponent as ShareIcon } from "../../assets/images/share-icon.svg";
import { ReactComponent as AddUserIcon } from "../../assets/images/user-add-icon.svg";
import { ReactComponent as ReloadIcon } from "../../assets/images/arrow-path-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/check-icon.svg";
import useWindowSize from "../../hooks/useWindowSize";
import CustomChat from "../../components/Chat/CustomChat";
import { firestoreDB } from "../../plugins/firebase";
import { SavedListingContext } from "../../App";
import HeaderMay2024 from "../../layouts/Main/HeaderMay2024";

export const Dashboard = () => {
  const mycontext = useContext(SavedListingContext);
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const colorMode = useSelector((state) => state.theme.mode);
  const [savedProperties, setSavedProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useWindowSize();
  const [currentTab, setCurrentTab] = useState("1");
  const [isAskingLoginModal, setIsAskingLoginModal] = useState(false);

  const showAuthModal = () => {
    setIsAskingLoginModal(true);
  };
  const handleShowAuthModal = () => {
    setIsAskingLoginModal(false);
    navigate("/auth/login");
  };
  const handleHideAuthModal = () => {
    setIsAskingLoginModal(false);
  };

  useEffect(() => {
    if (authState.userInfo?.uid) {
      handleLoadSavedHomes();
    }
  }, [authState.userInfo?.uid, mycontext.state.listing]);

  const handleLoadSavedHomes = async () => {
    setLoading(true);
    const q = query(
      collection(firestoreDB, "saved_listings"),
      where("uid", "==", authState.userInfo.uid)
    );
    const querySnapshot = await getDocs(q);
    const savedListing = [];
    querySnapshot.forEach((doc) => {
      let property = {
        doc_id: doc.id,
        ...doc.data(),
      };
      property = {
        zpid: property.doc_id,
        listing_id: property.listing_id,
        imgSrc: property.photo_url,
        price: property.price,
        bedrooms: property.beds,
        bathrooms: property.baths,
        address: property.title,
      };
      savedListing.push(property);
    });
    setSavedProperties(savedListing);
    setLoading(false);
  };

  const removeSavedHome = (docId, e) => {
    e.stopPropagation();
    const docRef = doc(firestoreDB, "saved_listings", docId);
    deleteDoc(docRef)
      .then(() => {
        handleLoadSavedHomes();
      })
      .catch((error) => {
        console.log(error);
        alert("Error");
      });
  };

  const isSavedProperty = (home) => {
    if (!authState.isAuthenticated) {
      return false;
    }
    return savedProperties?.some((item) => item.listing_id === home.listing_id);
  };

  const handleFavoriteOnCarousel = (e = null, home = null) => {
    if (e) {
      e.stopPropagation();
    }

    if (!authState.isAuthenticated) {
      sessionStorage.setItem("previous_path", window.location.pathname);
      showAuthModal(true);
      return;
    }

    const saveToFirebase = async (listing) => {
      await addDoc(collection(firestoreDB, "saved_listings"), {
        uid: authState.userInfo.uid,
        ...listing,
      });

      mycontext.dispatch({
        type: "add",
        payload: listing,
      });
    };

    if (
      savedProperties?.some(
        (property) => property.listing_id === home.listing_id
      )
    ) {
      removeSavedHome(home.doc_id, e);
    } else {
      saveToFirebase(home);
    }
  };

  const items = [
    {
      key: "1",
      label: `New Chat`,
      children: (
        <CustomChat
          colorMode={colorMode}
          handleFavorite={handleFavoriteOnCarousel}
          isSavedProperty={isSavedProperty}
        />
      ),
    },
    {
      key: "2",
      label: `Saved Properties`,
      children: (
        <SavedProperties
          isAuthenticated={authState.isAuthenticated}
          savedProperties={savedProperties}
          colorMode={colorMode}
          removeSavedHome={removeSavedHome}
          isMobile={isMobile}
        />
      ),
    },
  ];

  return (
    <DashboardPage className="dashboard-page">
      <HeaderMay2024 isMobile={isMobile} />

      {loading && (
        <SpinWrapper>
          <Spin size="large" />
        </SpinWrapper>
      )}
      <DashboardWrapper>
        {isMobile ? (
          <>
            {authState.isAuthenticated && (
              <InfoSection
                name={`${authState.userInfo?.firstName?.[0]} ${authState.userInfo?.lastName?.[0]}`}
                savedProperties={savedProperties?.length || 0}
                openedChat={7}
                colorMode={colorMode}
                isMobile={isMobile}
              />
            )}
            <StyledTabs
              defaultActiveKey="1"
              items={items}
              onChange={(e) => setCurrentTab(e)}
            />
            {/* {currentTab === "1" && <MobileControls colorMode={colorMode} />} */}
          </>
        ) : (
          <div className="flex flex-col gap-10 pt-10 pb-20 px-20 ">
            <ChatbotWrapper className="" colorMode={colorMode}>
              <div className="chat-section">
                <CustomChat
                  colorMode={colorMode}
                  handleFavorite={handleFavoriteOnCarousel}
                  isSavedProperty={isSavedProperty}
                />
              </div>
              <div className="control-section">
                <StyledFloatButton
                  type="primary"
                  shape="circle"
                  icon={<ShareIcon />}
                ></StyledFloatButton>
                <StyledFloatButton
                  type="primary"
                  shape="circle"
                  icon={<AddUserIcon />}
                ></StyledFloatButton>
                <StyledFloatButton
                  type="primary"
                  shape="circle"
                  icon={<ReloadIcon />}
                ></StyledFloatButton>
                {/* bb */}
                <StyledFloatButton
                  type="primary"
                  shape="circle"
                  icon={<CheckIcon />}
                  style={{ marginTop: "auto" }}
                ></StyledFloatButton>
              </div>
            </ChatbotWrapper>
            <SavedSearchWrapper>
              <SavedProperties
                isAuthenticated={authState.isAuthenticated}
                savedProperties={savedProperties}
                colorMode={colorMode}
                removeSavedHome={removeSavedHome}
              />
              {authState.isAuthenticated && (
                <InfoSection
                  name={`${authState.userInfo?.firstName?.[0]} ${authState.userInfo?.lastName?.[0]}`}
                  savedProperties={savedProperties?.length || 0}
                  openedChat={7}
                  isMobile={isMobile}
                  colorMode={colorMode}
                />
              )}
            </SavedSearchWrapper>
          </div>
        )}
      </DashboardWrapper>
      <Modal
        open={isAskingLoginModal}
        title="Do you want to save properties?"
        onOk={handleShowAuthModal}
        onCancel={handleHideAuthModal}
      >
        In order to save properties please log in using your email.
      </Modal>
    </DashboardPage>
  );
};
