import {
  CardElement,
  useElements,
  useStripe,
  AddressElement,
} from "@stripe/react-stripe-js";
import { Button, Input, Space, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSubscriptionInfo, createSubscription } from "../../store/stripe";

import "./style.scss";
import { useParams } from "react-router-dom";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const CheckoutForm = () => {
  // collect data from the user
  const [name, setName] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState("");
  const [priceId, setPriceId] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  // stripe items
  const stripe = useStripe();
  const elements = useElements();
  const paymentState = useSelector((state) => state.stripe);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (paymentState?.paymentInfo?.clientSecret && !paymentState.loading) {
      confirmCardPayment();
    }
  }, [paymentState]);

  const confirmCardPayment = async () => {
    setLoading(true);
    const confirmPayment = await stripe?.confirmCardPayment(
      paymentState.paymentInfo.clientSecret
    );
    if (confirmPayment?.error) {
      api.error({
        message: "Failed!",
        description: confirmPayment.error.message,
        duration: 2,
      });
    } else {
      api.success({
        message: "Payment succeeded",
        description: "This payment successfully set up",
        duration: 2,
      });
    }
    dispatch(clearSubscriptionInfo());
    setEmail("");
    setName("");
    elements.getElement(CardElement).clear();
    setLoading(false);
  };

  // main function
  const handleSubscribe = async () => {
    try {
      let billingDetails = null;
      const addressElement = elements.getElement("address");

      addressElement.getValue().then(function (result) {
        if (result.complete) {
          billingDetails = result.value;
        }
      });
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          ...billingDetails,
          email: email,
        },
      });
      const payload = {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        billing_details: {
          ...billingDetails,
          email: email,
        },
        pricingId: params.type,
      };
      dispatch(createSubscription(payload));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      {contextHolder}
      <CardElement otions={CARD_ELEMENT_OPTIONS} />
      <Space
        className="email-field"
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginBottom: 10,
          padding: "10px 12px",
          background: "#f9d0b5",
          borderRadius: 4,
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography.Text style={{ color: "#30313d" }}>Email</Typography.Text>
        <Input
          type="email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
          style={{
            height: 44,
            background: "white",
            border: "none",
            color: "black",
            width: "100%",
          }}
        />
      </Space>
      <AddressElement
        options={{
          mode: "billing",
          autocomplete: {
            mode: "google_maps_api",
            apiKey: "AIzaSyAR6QrMzFPNywR0i92blHVmKQjNVOT1990",
          },
          fields: {
            phone: "always",
            email: "always",
          },
          validation: {
            phone: {
              required: "always",
            },
          },
          display: {
            name: "split",
          },
        }}
      />
      <Button
        onClick={handleSubscribe}
        disabled={!stripe}
        type="primary"
        style={{ marginTop: 10 }}
        loading={paymentState.isLoading || loading}
      >
        Subscribe
      </Button>
    </div>
  );
};
