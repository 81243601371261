// import Image from "next/image";
import React from "react";

import chatbotImage from "../../assets/images/chat2.png";
import customizeImage from "../../assets/images/colorscheme1.png";
import clientsImage from "../../assets/images/portraits/clients.png";
import analyticsImage from "../../assets/images/portraits/analytics.png";
import Step from "../Step";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ButtonStandard from "../ButtonStandard";

export default function Zigzag({ isMobile }) {
  return (
    <div className=' '>
      <div className=' mx-auto px-4 lg:px-32 text-white   '>
        <div className='py-12 lg:py-20 border-t border-gray-800'>
          {/* div header */}

          <h2 className='text-3xl lg:text-6xl mb-40 text-center text-houndBlue font-normal'>
            House Hound is a home base for your clients to select properties,
            schedule showings and collaborate with you.{" "}
          </h2>

          {/* Items */}
          <div className='flex flex-col md:gap-48 gap-24 w-full'>
            {/* 1st item */}

            <Step
              imgSrc={
                isMobile
                  ? "/MOBILE/GIF/2/CustomiseChatbotMobile.gif"
                  : "/DESKTOP/gif2/Step1CustomiseChatbot.gif"
              }
              flexDirection='md:flex-row flex-col'
              stepNumber='Step 1'
              stepText='Customize your client facing environment'
              showButton={true}
              showTitle={true}
            />
            <Step
              imgSrc={
                isMobile
                  ? "/MOBILE/GIF/2/ChatbotLinkMobile.gif"
                  : "/DESKTOP/gif2/Step2ChatbotLink.gif"
              }
              flexDirection='md:flex-row-reverse flex-col'
              stepNumber='Step 2'
              stepText='Create your link and send it to the customer'
              showButton={true}
              showTitle={true}
            />

            {/* Step 3 div */}
            {isMobile && (
              <div className='flex flex-col gap-8 items-center justify-center'>
                <Step
                  imgSrc={isMobile ? "/MOBILE/JPEG/CLientInfo2.png" : " "}
                  flexDirection='md:flex-row flex-col'
                  stepNumber='Step 3'
                  stepText='A) Get to know your clients'
                  showTitle={true}
                  step3={true}
                />
                <Step
                  imgSrc={isMobile ? "/MOBILE/JPEG/Listing.jpeg" : " "}
                  flexDirection='md:flex-row flex-col'
                  stepNumber='Step 3'
                  stepText='B) Send your clients the most relevant available properties'
                  step3={true}
                />
                <Step
                  imgSrc={isMobile ? "/MOBILE/JPEG/Calendar.jpeg" : " "}
                  flexDirection='md:flex-row flex-col'
                  stepNumber='Step 3'
                  stepText='C) Schedule tours based on your availability'
                  step3={true}
                />
                <ButtonStandard
                  textSize={"text-2xl"}
                  py={"py-6"}
                  // px={"px-40"}
                  width={"w-72"}
                  href='https://hh.househound.ai/signup'
                  text={"Start Now"}
                />
              </div>
            )}
          </div>
        </div>

        {!isMobile && (
          <div className='pt-20 flex flex-col gap-20 items-center'>
            <div className=''>
              <span className='text-6xl font-semibold'>Step 3 </span>
              <span className='text-6xl font-thin '>• Let House Hound</span>
            </div>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              swipeable={true}
            >
              <img src='/DESKTOP/step3a.png' />
              <img src='/DESKTOP/step3b.png' />
              <img src='/DESKTOP/step3c.png' />
            </Carousel>
            <ButtonStandard
              textSize={"text-2xl"}
              py={"py-6"}
              px={"px-40"}
              width={"w-72"}
              href='https://hh.househound.ai/signup'
              text={"Start Now"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
