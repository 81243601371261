import { Button, Form, Space } from "antd";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  padding: 24px;
  max-width: 530px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1e1e1e;

  img {
    width: 110px;
    margin: auto;
  }

  .title {
    font-size: 40px;
    font-weight: 400;
    line-height: 53px;
    color: #fff;
    text-align: center;
    margin-bottom: 60px;
  }

  .ant-form-item {
    width: 100%;

    .ant-input-affix-wrapper {
      background: transparent;
      border: 1px solid #9a9a9a;
      padding: 28px 30px;

      .ant-input {
        background: transparent;
      }
    }
  }

  button[type="submit"] {
    width: 100%;
  }

  a {
    color: #1e1e1e;
  }

  .form-label {
    display: none;
  }

  @media screen and (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 40px;
    justify-content: space-between;
    min-height: 100%;

    .title {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 40px;
    }

    .ant-form-item .ant-input-affix-wrapper {
      padding: 14px 18px;

      .ant-input {
        font-size: 14px;
      }
    }

    .form-label {
      display: block;
      line-height: 15px;
      margin-bottom: 8px;
    }
  }
`;

export const StyledSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  background: #659fca;
  padding-top: 24px;
  padding-bottom: 24px;
  height: fit-content;
  font-size: 18px;
  font-weight: 500;
  // box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15),
  //   0px 2px 5px 0px rgba(0, 0, 0, 0.15), 0px 9px 9px 0px rgba(0, 0, 0, 0.13),
  //   0px 21px 13px 0px rgba(0, 0, 0, 0.08), 0px 37px 15px 0px rgba(0, 0, 0, 0.02),
  //   0px 58px 16px 0px rgba(0, 0, 0, 0);

  @media screen and (max-width: 767px) {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const StyledSocialButton = styled(Button)`
  width: 100%;
  padding: 14px 8px;
  height: fit-content;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.09),
    0px 3px 2px 0px rgba(0, 0, 0, 0.05), 0px 5px 2px 0px rgba(0, 0, 0, 0.01),
    0px 8px 2px 0px rgba(0, 0, 0, 0);

  img {
    height: 18px;
    width: auto;
    object-fit: contain;
  }
`;

export const StyledSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px !important;
  margin-top: 40px;
  width: 100%;
`;

export const StyledSocialButtons = styled(Space)`
  gap: 24px !important;
  width: 100%;

  .ant-space-item {
    flex: 1;
  }
`;
