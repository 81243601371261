import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { ConfigProvider, theme } from "antd";

import { AppHeader } from "./AppHeader";
import GlobalControls from "./GlobalControls";
import { StyledLayout } from "./styles";
import SessionService from "../../services/SessionService";
import { setToken, setUserInfo } from "../../store/auth";
import { db } from "../../plugins/firebase";
import { onValue, ref } from "firebase/database";
import { loadHomes } from "../../store/favorite";
import { StyledAppWrapper } from "./styles";
import HeaderMay2024 from "./HeaderMay2024";

const { darkAlgorithm, defaultAlgorithm } = theme;
const { Content } = Layout;

export const Main = () => {
  const themeInfo = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const isLoggedIn = SessionService.isLoggedIn();
    const userInfo = SessionService.getUserData();
    const token = SessionService.getAccessToken();

    if (isLoggedIn) {
      dispatch(
        setUserInfo({
          ...userInfo,
        })
      );
      dispatch(setToken(token));
      handleLoadSavedHomes(userInfo.uid);
    }
  }, []);

  const handleLoadSavedHomes = (uid) => {
    const userRef = ref(db, "users/" + uid);
    let userData = null;
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      userData = data;

      if (userData.savedHomes) {
        const payload = {
          savedHomes: userData.savedHomes,
        };
        dispatch(loadHomes(payload));
      }
    });
  };
  return (
    <ConfigProvider
      theme={{
        algorithm: themeInfo.mode === "dark" ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: "#6ccff6",
        },
      }}
    >
      <StyledAppWrapper mode={themeInfo.mode}>
        {/* <AppHeader isHomePage={false} /> */}
        <StyledLayout mode={themeInfo.mode}>
          <Content>
            <Outlet />
          </Content>
        </StyledLayout>
        {/* Controls for the bottom right  */}
        {/* <GlobalControls /> */}
      </StyledAppWrapper>
    </ConfigProvider>
  );
};
