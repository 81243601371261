import React from "react";
import { FaTimes } from "react-icons/fa";
import HHLogo from "../../assets/HHLogos/HHLogo.png"; // Adjust the path as necessary

const MobileToolbar = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gradient-to-b from-houndBlue to-houndBlueD4 z-50 flex flex-col p-6 h-80">
      <div className="flex justify-between items-center mb-6">
        <img src={HHLogo} alt="HouseHound Logo" className="h-8" />
        <button onClick={onClose} className="bg-transparent border-0">
          <FaTimes className="text-black text-2xl" />
        </button>
      </div>
      <nav className="flex flex-col space-y-4 text-white text-xl">
        <a href="/main/home" className="text-white text-2xl">
          Home
        </a>
        <a href="/main/dashboard" className="text-white text-2xl">
          Chat Search
        </a>
        <a href="/main/search" className="text-white text-2xl">
          Map Search
        </a>
        <a
          href="https://hh.househound.ai/login"
          className="bg-black text-white py-2 px-4 rounded-full text-center"
        >
          Login
        </a>
      </nav>
    </div>
  );
};

export default MobileToolbar;
