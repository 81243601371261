import React from "react";
import ButtonStandard from "../ButtonStandard";

export default function CallToAction(isMobile = false) {
  return (
    <div class='text-center space-y-6'>
      <p className='text-houndBlueD4 font-bold  text-[60px] hidden lg:block '>
        Don’t work alone!
      </p>
      <p className='text-houndBlueD4 font-bold text-6xl lg:text-5xl lg:hidden '>
        Don’t <br /> work <br /> alone!
      </p>
      <p className='text-houndBlueD3 font-bold text-3xl lg:text-4xl '>
        Keep your clients engaged so they stick with you
      </p>
    </div>
  );
}
