import React, { useState, ChangeEvent, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";

const PriceDropdown = () => {
  const rentalPriceOptions = [
    500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500,
    3750, 4000, 4250, 4500, 4750, 5000, 5500, 6000, 6500, 7000, 7500, 8000,
  ];

  const buyingPriceOptions = [
    50000, 75000, 100000, 125000, 150000, 175000, 200000, 225000, 250000,
    275000, 300000, 350000, 400000, 450000, 500000, 550000, 600000, 650000,
    700000, 750000, 800000, 850000, 900000, 950000, 1000000, 1100000, 1200000,
    1300000, 1400000, 1500000, 1600000, 1700000, 1800000, 1900000, 2000000,
    2250000, 2500000, 2750000, 3000000, 3500000, 4000000, 4500000, 5000000,
    6000000, 7000000, 8000000, 9000000, 10000000,
  ];

  useEffect(()=>{
    sessionStorage.setItem('min_price', "500")
    sessionStorage.setItem('max_price', "8000")
    sessionStorage.setItem('bed', "1")
    sessionStorage.setItem('target_markert','for-rent')

  },[])

  const [priceRange, setPriceRange] = useState<{ min: number; max: number }>({
    min: rentalPriceOptions[0],
    max: rentalPriceOptions[rentalPriceOptions.length - 1],
  });
  const [propertyType, setPropertyType] = useState<"for-buy" | "for-rent">("for-rent");

  const [bed, setBeds]=useState(1)

  const handlePropertyTypeChange = (
    event: SelectChangeEvent<"for-buy" | "for-rent">
  ) => {
    setPropertyType(event.target.value as "for-buy" | "for-rent");
    const options =
      event.target.value === "for-buy" ? buyingPriceOptions : rentalPriceOptions;
    setPriceRange({ min: options[0], max: options[options.length - 1] });
    // alert(event.target.value)
    sessionStorage.setItem('target_markert',event.target.value)
  };

  const handleMinPriceChange = (event: SelectChangeEvent<number>) => {
    setPriceRange((prev) => ({
      ...prev,
      min: parseInt(event.target.value as unknown as string),
    }));
    // alert(event.target.value)
    sessionStorage.setItem('min_price', event.target.value.toString())
  };

  const handleMaxPriceChange = (event: SelectChangeEvent<number>) => {
    setPriceRange((prev) => ({
      ...prev,
      max: parseInt(event.target.value as unknown as string),
    }));
    // alert(event.target.value)
    sessionStorage.setItem('max_price', event.target.value.toString())
  };

  const handleBedsChange=(event:any)=>{
    sessionStorage.setItem('beds',event.target.value)
    setBeds(event.target.value)


  }


  const currentOptions =
    propertyType === "for-buy" ? buyingPriceOptions : rentalPriceOptions;

  return (
    <div className="text-black  flex gap-4 items-center p-4 m-2 bg- w-fit mx-auto border border-black rounded-lg">
      <div className="flex flex-col gap-2">
        <label>Beds:</label>
        <Select
          className="bg-white"
          value={bed}
          onChange={handleBedsChange}
        >
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
        </Select>
      </div>
      <div className="flex flex-col gap-2">
        <label>Property Type:</label>
        <Select
          className="bg-white"
          value={propertyType}
          onChange={handlePropertyTypeChange}
        >
          <MenuItem value="for-rent">Rent</MenuItem>
          <MenuItem value="for-buy">Buy</MenuItem>
        </Select>
      </div>
      <div className="flex flex-col gap-2">
        <label>Min Price:</label>
        <Select
          className=" text-black"
          value={priceRange.min}
          onChange={handleMinPriceChange}
        >
          {currentOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
              disabled={option > priceRange.max}
            >
              {option.toLocaleString()}
            </MenuItem>
          ))}
        </Select>
      </div>
      <span className="pt-8">-</span>
      <div className="flex flex-col gap-2">
        <label>Max Price:</label>
        <Select
          className="bg-white"
          value={priceRange.max}
          onChange={handleMaxPriceChange}
        >
          {currentOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
              disabled={option < priceRange.min}
            >
              {option.toLocaleString()}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default PriceDropdown;
