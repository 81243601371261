import { useEffect, useState } from "react";
import { gsap } from "gsap";
import AOS from "aos";
import Hero from "../../components/Template/hero.tsx";

import Zigzag from "../../components/Template/zigzag.tsx";
import Footer from "../../components/Template/ui/footer.tsx";
import CallToAction from "../../components/Template/CallToAction.js";
import Statistics2 from "../../components/Statistics2.js";
import DashboardView from "../../components/DashboardView.js";

import PriceCard from "../../components/PriceCard.js";
import HeaderMay2024 from "../../layouts/Main/HeaderMay2024.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// const { Title } = Typography;
import "./styles.css";
import useWindowSize from "../../hooks/useWindowSize";
import ButtonStandard from "../../components/ButtonStandard.js";

export const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    gsap.fromTo(
      ".hero-left-section",
      { x: -600, opacity: 0.1 },
      { x: 0, duration: 1, opacity: 1 }
    );
    gsap.fromTo(
      ".image-wrapper",
      { x: 600, opacity: 0.1 },
      { x: 0, duration: 1, opacity: 1 }
    );
  }, []);

  const { isMobile } = useWindowSize();

  return (
    <div className='w-full bg-gradient-to-b from-[#05080d] to-houndBlueL5 via-[#253a5f] flex flex-col  lg:pt-16 pt-0 '>
      <div className='absolute top-0 w-full '>
        <HeaderMay2024 isMobile={isMobile} />
      </div>
      <div className='px-4 flex flex-col gap-20 lg:gap-40 pt-20'>
        <div className='  py-20'>
          <Hero isMobile={isMobile} />
        </div>
        <div className='lg:py-20'>
          <Statistics2 />
        </div>
        <div className=' lg:pb-20'>
          <Zigzag isMobile={isMobile} />
        </div>
        <div className='flex flex-col gap-20 items-center'>
          <DashboardView
            imageSrc={
              isMobile
                ? "/MOBILE/GIF/2/Dashboard.gif"
                : "/DESKTOP/gif2/Dashboard.gif"
            }
            iconType='speedometer'
            headingText='This is your dashboard where you and your client can refine your search in a collaborative environment'
          />
          <DashboardView
            imageSrc={
              isMobile
                ? "/MOBILE/GIF/2/ClientInfo.gif"
                : "/DESKTOP/gif2/UserDetails.gif"
            }
            iconType='person'
            headingText='Get to know your clients with unparalleled detail'
          />
          <DashboardView
            showButton={true}
            imageSrc={
              isMobile
                ? "/MOBILE/GIF/2/MapSearching.gif"
                : " /DESKTOP/gif2/MapSearchNew.gif"
            }
            iconType='location'
            headingText='Your client can search via map search as well and add homes your “Tour List”'
          />

          <ButtonStandard
            textSize={"text-2xl"}
            py={"py-6"}
            // px={"px-40"}
            width={"w-72"}
            href='https://hh.househound.ai/signup'
            text={"Start Now"}
          />
        </div>
        <div className='py-10 space-y-16'>
          <CallToAction />
          <PriceCard
            monthly={true}
            planName='Monthly'
            monthlyPrice='49.99'
            buttonHref='#'
            headerColor='bg-[#499fdf]'
          />
        </div>
        <div className='text-houndWhite text-center text-3xl lg:text-5xl font-semibold leading-relaxed py-20 lg:pb-40 pb-32'>
          Give your clients access to everything that’s on the MLS, <br /> and
          everything that’s not!
        </div>
      </div>
      <Footer />
    </div>
  );
};
