import { Button, Drawer, Layout, Menu, Switch } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

import LightModeBg from "../../assets/images/lightModeBg.png";
import SunIcon from "../../assets/images/sun.svg";

const { Header, Footer } = Layout;
export const StyledAppWrapper = styled.div`
  background-repeat: no-repeat;
  background-color: #141f28;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
`;

export const StyledLayout = styled(Layout)`
  min-height: calc(100vh - 145px);
  background: transparent;
  .ant-layout-content {
    height: 100%;
  }

  @media screen and (max-width: 960px) {
    min-height: calc(100vh - 103px);
  }
`;

export const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: content-box;
  font-size: 30px;
  background: transparent;
  padding: 20px 30px;
  height: fit-content;

  @media screen and (max-width: 960px) {
    padding: 30px 14px;
  }

  .ant-menu {
    background: ${(props) => (props.mode === "dark" ? "black" : "white")};
  }
`;

export const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  @media screen and (max-width: 960px) {
    img {
      height: 43px;
    }
    .ant-menu {
      display: none;
    }
  }
`;

export const StyledHeaderMenu = styled(Menu)`
  width: 440px;

  border-radius: 60px;
  background: ${(props) =>
    props.colorMode === "dark"
      ? "rgba(117, 117, 117, 0.3)"
      : "#e8e8e8"} !important;
  box-shadow: ${(props) =>
    props.colorMode === "dark"
      ? "0px 0px 0px 0px rgba(0, 0, 0, 0.50), 0px 2px 5px 0px rgba(0, 0, 0, 0.49), 0px 9px 9px 0px rgba(0, 0, 0, 0.43), 0px 20px 12px 0px rgba(0, 0, 0, 0.25), 0px 36px 14px 0px rgba(0, 0, 0, 0.07), 0px 56px 16px 0px rgba(0, 0, 0, 0.01)"
      : "0px 0px 0px 0px rgba(0, 0, 0, 0.20), 0px 2px 5px 0px rgba(0, 0, 0, 0.20), 0px 9px 9px 0px rgba(0, 0, 0, 0.17), 0px 20px 12px 0px rgba(0, 0, 0, 0.10), 0px 36px 14px 0px rgba(0, 0, 0, 0.03), 0px 56px 16px 0px rgba(0, 0, 0, 0.00)"};

  .ant-menu-item {
    padding-inline: 40px;
    font-size: 16px;
    &:after {
      display: none;
    }
  }
  .ant-menu-item-selected {
    color: #fff !important;
    border-radius: 60px !important;
    background: ${(props) =>
      props.colorMode === "dark"
        ? "linear-gradient(90deg, #1B1B1B 0%, #4E4E4E 100%)"
        : "linear-gradient(90deg, #000 0%, #3c3c3c 100%)"};
    box-shadow: ${(props) =>
      props.colorMode === "dark"
        ? "4px 0px 4px 0px rgba(0, 0, 0, 0.35)"
        : "4px 0px 4px 0px rgba(0, 0, 0, 0.35)"};
  }
  &:before {
    display: none;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: 36px;
  .ant-dropdown-trigger.header-drop-down {
    display: none;
  }

  @media screen and (max-width: 960px) {
    gap: 24px;
    .ant-dropdown-trigger.header-drop-down {
      display: block;
    }
    a {
      display: none;
    }
  }
`;

export const StyledSwitch = styled(Switch)`
  height: 36px;
  min-width: 73px;
  background: #e8e8e8 !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 8px 8px 0px rgba(0, 0, 0, 0.17),
    0px 18px 11px 0px rgba(0, 0, 0, 0.1), 0px 32px 13px 0px rgba(0, 0, 0, 0.03),
    0px 50px 14px 0px rgba(0, 0, 0, 0);

  .ant-switch-handle {
    width: 30px;
    height: 30px;
    top: 3px;
    &:before {
      border-radius: 50%;
      background: #1e1e1e;
      filter: drop-shadow(
        1.0691823959350586px 1.0691823959350586px 2.6729559898376465px
          rgba(0, 0, 0, 0.5)
      );
      background-image: url(${SunIcon});
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.ant-switch.ant-switch-checked {
    background: #585858 !important;
    .ant-switch-handle {
      inset-inline-start: calc(100% - 33px);
    }
  }

  @media screen and (max-width: 960px) {
    height: 20px;
    min-width: 40px;

    .ant-switch-handle {
      width: 16px;
      height: 16px;
      top: 2px;

      &:before {
        background-size: 10px;
      }
    }

    &.ant-switch.ant-switch-checked {
      background: #585858 !important;
      .ant-switch-handle {
        inset-inline-start: calc(100% - 20px);
      }
    }
  }
`;

export const LoginButton = styled(Button)`
  border-radius: 48.857px;
  color: ${(props) =>
    props.mode === "dark" ? "#ffffff" : "#1E1E1E"} !important;
  background: ${(props) =>
    props.mode === "dark" ? "rgba(88, 88, 88, 0.5)" : "#e8e8e8"} !important;
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.2))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))
    drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.17))
    drop-shadow(0px 11px 7px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.03))
    drop-shadow(0px 31px 9px rgba(0, 0, 0, 0));
  padding: 15px 46px;
  height: fit-content;
  font-size: 16px;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const CustomFooter = styled(Footer)`
  background: white;
  border-top: 1px solid rgb(153, 153, 153);

  @media screen and (max-width: 960px) {
    padding: 20px 12px;
  }
`;
export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer-left {
    img {
      width: 100px;
      height: 80px;
    }
  }
  .footer-right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a:nth-child(2) {
      margin: 0 20px;

      @media screen and (max-width: 960px) {
        margin: 0 5px;
      }
    }
  }
`;

export const AvatarWrapper = styled.div`
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const StyledMenuButton = styled(Button)`
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  display: none;
  color: #ffffff !important;
  svg {
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 960px) {
    display: inline-block;
  }
`;

export const GlobalControlsContainer = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 40px;
  right: 70px;
  border-radius: 60px;
  display: flex;
  gap: 18px;
  padding: 12px 20px;
  background: linear-gradient(
    85deg,
    rgba(101, 159, 202, 0.9) 0%,
    rgba(149, 194, 228, 0.9) 29.69%,
    rgba(149, 194, 228, 0.9) 72.4%,
    rgba(101, 159, 202, 0.9) 100%
  );
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5),
    0px 1px 3px 0px rgba(0, 0, 0, 0.49), 0px 5px 5px 0px rgba(0, 0, 0, 0.43),
    0px 11px 7px 0px rgba(0, 0, 0, 0.25), 0px 20px 8px 0px rgba(0, 0, 0, 0.07),
    0px 32px 9px 0px rgba(0, 0, 0, 0.01);

  button.ant-btn {
    background: #ffffff;
    border: none;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.2))
      drop-shadow(
        0px 0.8837681412696838px 1.7675362825393677px rgba(0, 0, 0, 0.2)
      )
      drop-shadow(
        0px 3.5350725650787354px 3.5350725650787354px rgba(0, 0, 0, 0.17)
      )
      drop-shadow(0px 7.95391321182251px 5.302608966827393px rgba(0, 0, 0, 0.1))
      drop-shadow(
        0px 14.140290260314941px 6.186377048492432px rgba(0, 0, 0, 0.03)
      )
      drop-shadow(0px 22.97797203063965px 6.186377048492432px rgba(0, 0, 0, 0));
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const StyledDrawer = styled(Drawer)`
  background: ${(props) =>
    props.mode === "dark"
      ? "#0B0B0B"
      : "linear-gradient(180deg, #659FCA 0%, rgba(173, 204, 227, 1) 70.10%, rgba(255, 255, 255, 1) 100%)"} !important;

  .ant-drawer-body {
    padding: 0;
  }
`;

export const StyleMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;
  padding: 40px 32px;
`;

export const StyleMenuItem = styled(Link)`
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
  }
`;

export const StyledMobileLoginButton = styled(Button)`
  border-radius: 10px;
  background: #659fca;
  padding: 14px 25px;
  height: fit-content;
  width: fit-content;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 14px;

  svg {
    width: 24px;
    height: 24px;
  }
`;
