import styled from "styled-components";
import { Button, Select, Switch } from "antd";
import Draw from "../../assets/images/draw.svg";
import Sort from "../../assets/images/sort.svg";
import SortWhite from "../../assets/images/sort-white.svg";

export const SearchResultWrapper = styled.div`
  max-width: 720px;
  overflow-y: auto;
  align-items: flex-start;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  @media screen and (max-width: 1200px) {
    width: 375px;
    min-width: 375px;
    padding: 16px;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const SpinWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
`;

export const MapWrapper = styled.div`
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const SearchSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  // height: calc(100vh - 145px);
  overflow: hidden;
  background: ${(props) =>
    props.colorMode === "dark"
      ? "#0B0B0B"
      : "linear-gradient(134deg, #FFF 0%, rgba(255, 255, 255, 0.90) 54.48%, rgba(255, 255, 255, 0.80) 100%)"};

  @media screen and (max-width: 960px) {
    min-height: calc(100vh - 103px);
  }
`;

export const SearchInputs = styled.div`
  gap: 8px;
  display: flex;
  position: relative;
  padding: 18px 28px 30px 28px;
  z-index: 1;
  background: rgba(242, 242, 242, 0.9);

  .pac-target-input {
    padding: 4px 11px;
    font-size: 14px;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    width: 300px;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .home-results {
    width: calc(100% + 50px);
  }
`;

export const SearchPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  height: 50px;
  width: 100%;
`;

export const SearchAddress = styled.div`
  border-radius: 10px;
  background: ${(props) =>
    props.colorMode === "dark"
      ? "rgba(88, 88, 88, 0.5)"
      : "linear-gradient(134deg, #FFF 0%, rgba(255, 255, 255, 0.90) 54.48%, rgba(255, 255, 255, 0.80) 100%)"};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25),
    0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.21),
    0px 9px 5px 0px rgba(0, 0, 0, 0.13), 0px 16px 7px 0px rgba(0, 0, 0, 0.04),
    0px 25px 7px 0px rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: between;
  max-width: 250px;
  flex: 1;
  height: 100%;
  padding: 0 0 0 18px;

  .ant-select {
    flex: 1;
    overflow: hidden;
    .ant-select-selector {
      border: none;
      box-shadow: none !important;
      background: transparent;
      padding: 0;
      .ant-select-selection-placeholder {
        padding-inline-start: 10px;
      }
    }
  }
`;

export const SearchPopupButton = styled(Button)`
  border-radius: 10px;
  background: ${(props) =>
    props.colorMode === "dark"
      ? "rgba(88, 88, 88, 0.5)"
      : "linear-gradient(134deg, #FFF 0%, rgba(255, 255, 255, 0.90) 54.48%, rgba(255, 255, 255, 0.80) 100%)"};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25),
    0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.21),
    0px 9px 5px 0px rgba(0, 0, 0, 0.13), 0px 16px 7px 0px rgba(0, 0, 0, 0.04),
    0px 25px 7px 0px rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  gap: 8px;
  &:hover,
  &.ant-popover-open {
    background: #659fca !important;
    color: #ffffff !important;
  }
`;

export const StyledDrawingSwitch = styled(Switch)`
  height: 36px;
  min-width: 73px;
  background: #e8e8e8 !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 8px 8px 0px rgba(0, 0, 0, 0.17),
    0px 18px 11px 0px rgba(0, 0, 0, 0.1), 0px 32px 13px 0px rgba(0, 0, 0, 0.03),
    0px 50px 14px 0px rgba(0, 0, 0, 0);

  .ant-switch-handle {
    width: 30px;
    height: 30px;
    top: 3px;
    &:before {
      border-radius: 50%;
      background: #659fca;
      filter: drop-shadow(
        1.0691823959350586px 1.0691823959350586px 2.6729559898376465px
          rgba(0, 0, 0, 0.5)
      );
      background-image: url(${Draw});
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-switch-inner {
    .ant-switch-inner-checked,
    .ant-switch-inner-unchecked {
      color: #969696;
    }
    .ant-switch-inner-checked {
      margin-inline-end: 6px !important;
      margin-top: 6px !important;
    }
    .ant-switch-inner-unchecked {
      margin-top: -20px !important;
      margin-inline-start: 6px !important;
    }
  }

  &.ant-switch.ant-switch-checked {
    .ant-switch-handle {
      inset-inline-start: calc(100% - 33px);
    }
  }
`;

export const SearchTopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ClearPolygon = styled.div`
  position: absolute;
  top: 132px;
  right: 65px;
  z-index: 10;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledSortSelect = styled(Select)`
  width: 195px;
  height: 50px;
  .ant-select-selector {
    display: flex;
    align-items: center;
    gap: 12px;
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background-image: ${(props) =>
        props.colorMode === "dark" ? `url(${SortWhite})` : `url(${Sort})`};
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &:hover,
  &.ant-select-open {
    .ant-select-selector::before {
      background-image: url(${SortWhite});
    }
  }
`;
