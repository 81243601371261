import React from "react";
import HeaderMay2024 from "../../layouts/Main/HeaderMay2024";

const Privacy = () => {
  return (
    <div>
      <HeaderMay2024 />
      <div className=" p-20 text-lg  bg-gradient-to-b from-houndBlueL1 to-houndBlueL5  flex flex-col ">
        <h1>Privacy Policy</h1>
        <p>
          Welcome to the Privacy Policy of House Hound, a Chicago-based real
          estate AI company. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website
          (http://www.househound.com) or use our services. Please read this
          Privacy Policy carefully. If you do not agree with the terms of this
          Privacy Policy, please do not access the website or use our services.
        </p>
        <h2>Collection of Information</h2>
        <p>
          We may collect personal information such as your name, email address,
          phone number, and other contact or demographic information when you
          voluntarily submit it through our website or other communication
          channels. We may also collect information automatically when you visit
          our website, including your IP address, browser type, operating
          system, referral URLs, and other usage information.
        </p>
        <h2>Use of Information</h2>
        <p>We may use the information we collect to:</p>
        <ul>
          <li>Provide, operate, and maintain our website and services.</li>
          <li>Respond to your inquiries and fulfill your requests.</li>
          <li>Send you marketing and promotional communications.</li>
          <li>Improve our website and services.</li>
          <li>Monitor and analyze usage trends and preferences.</li>
        </ul>
        <h2>Disclosure of Information</h2>
        <p>We may disclose your personal information:</p>
        <ul>
          <li>
            To our affiliates, third-party service providers, and partners who
            assist us in providing and improving our services.
          </li>
          <li>
            To comply with applicable laws and regulations, to respond to a
            subpoena, search warrant, or other lawful request for information we
            receive, or to otherwise protect our rights.
          </li>
        </ul>
        <h2>Security</h2>
        <p>
          We take reasonable measures to protect the information we collect from
          unauthorized access, use, and disclosure. However, please be aware
          that no method of transmission over the internet or electronic storage
          is 100% secure.
        </p>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about our Privacy Policy, please
          contact us at privacy@househound.com.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
