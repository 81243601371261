import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Button,
} from "@chatscope/chat-ui-kit-react";
import axios from "axios";

import PropertiesCarousel from "../PropertiesCarousel";
import Logo from "../../assets/images/logo.png";
import PriceDropDown from './PriceDropDown.tsx'
const backend_url = "https://api.roycetechnologies.co.ke/api/";

const url = "https://rasa.roycetechnologies.co.ke/";
//

export default function CustomChat({
  colorMode,
  handleFavorite,
  isSavedProperty,
}) {
  const [messages, setMessages] = useState([]);
  const [selectedReplies, setSelectedReplies] = useState([]);
  const [showpricedropdown, showPriceDown]=useState(false);

  useEffect(() => {
    initialMessage();
  }, []);

  const generateId = () => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  };

  const hadleNewMessage = (e) => {
    addOutGoingMessage(e);
    axios
      .post(url + "webhooks/rest/webhook", {
        message: e,
        sender: localStorage.getItem("session_id"),
      })
      .then((r) => {
        let dt = r.data;
        dt.map((d, index) => {
          if (r.data[0].text === "call api") {
            if (index > 2) {
              fetchListings(r.data[1].text, d);
            }
          } else {
            const obj = {
              messag: d.text,
              type: "incoming",
            };
            setMessages((prev) => [
              ...prev,
              {
                type: "conversation",
                conversation: obj,
                id: generateId(),
              },
            ]);
            if (d.buttons) {
              setMessages((prev) => [
                ...prev,
                {
                  type: "otherButtons",
                  buttons: d.buttons,
                  id: generateId(),
                },
              ]);
            }
          }
        });

        console.log(r);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const initialMessage = () => {
    const d = new Date();
    let time = d.getTime();

    localStorage.setItem("session_id", time);
    axios
      .post(url + "webhooks/rest/webhook", {
        message: "hi",
        sender: localStorage.getItem("session_id"),
      })
      .then((r) => {
        console.log(r);
        let dt = r.data;
        setMessages([]);
        dt.map((d) => {
          console.log(d.text);
          const obj = {
            messag: d.text,
            type: "incoming",
          };
          setMessages((prev) => [
            ...prev,
            {
              type: "conversation",
              conversation: obj,
              id: generateId(),
            },
          ]);
          if (d.buttons) {
            setMessages((prev) => [
              ...prev,
              { type: "buttons", buttons: d.buttons, id: "initial_locations" },
            ]);
          }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClickReply = (reply, isSelected) => {
    if (isSelected) {
      setSelectedReplies((prev) => prev.filter((item) => item !== reply));
    } else {
      setSelectedReplies((prev) => [...prev, reply]);
    }
  };

  const confirmLocation = () => {
    const reply = selectedReplies.join(", ");
    addOutGoingMessage(reply);
    let session_id = localStorage.getItem("session_id");

    axios
      .post(
        url +
          `conversations/${session_id}/trigger_intent?output_channel=latest`,
        {
          name: "setlocation",
          entities: {
            location: reply,
          },
        }
      )
      .then((r) => {
        
        console.log(r);
        if(r.data.messages[0].text=="Fill below form"){
          addIncomingText("Perfect, now I just need the following");
          showPriceDown(true)
          return;

        }
        console.log(r.data.messages[0].text);
        addIncomingText(r.data.messages[0].text);
        if (r.data.messages[0].buttons) {
          setMessages((prev) => [
            ...prev,
            {
              type: "otherButtons",
              buttons: r.data.messages[0].buttons,
              id: generateId(),
            },
          ]);
        }
        setMessages((prev) =>
          prev.filter((message) => message.id !== "initial_locations")
        );
        // Enable create profile form

        // if (payload.payload == "/create_profile") {
        //   setCreateProfile(true);
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const otherButtons = (param, id) => {
    setMessages((prev) => prev.filter((message) => message.id !== id));
    addOutGoingMessage(param);
    axios
      .post(url + "webhooks/rest/webhook", {
        message: param,
        sender: localStorage.getItem("session_id"),
      })
      .then((r) => {
        const obj = {
          messag: r.data[0].text,
          type: "incoming",
        };
        setMessages((prev) => [
          ...prev,
          { type: "conversation", conversation: obj, id: generateId() },
        ]);
        if (r.data[0].buttons) {
          setMessages((prev) => [
            ...prev,
            {
              type: "otherButtons",
              buttons: r.data[0].buttons,
              id: generateId(),
            },
          ]);
        }
        console.log(r);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addOutGoingMessage = (text) => {
    const obj = {
      messag: text,
      type: "outgoing",
    };
    setMessages((prev) => [
      ...prev,
      { type: "conversation", conversation: obj, id: generateId() },
    ]);
  };

  const addIncomingText = (text) => {
    const obj = {
      messag: text,
      type: "incoming",
    };
    setMessages((prev) => [
      ...prev,
      { type: "conversation", conversation: obj, id: generateId() },
    ]);
  };

  const fetchListings = (apitext, d) => {
    showPriceDown(false)
    // console.log("This is apitext")
    // console.log(apitext)
    // console.log("End of  apitext")
    // let mystr = apitext;
    // let array = mystr.split("|");
    // console.log(array);
    // let purpose = array[4] === "Rent" ? "for-rent" : "for-sale";


    let target_markert = sessionStorage.getItem("target_markert");
    let min_price = sessionStorage.getItem("min_price");
    let max_price = sessionStorage.getItem("max_price");
    let beds = sessionStorage.getItem("beds");

    axios
      .post(backend_url + "fetchlistings", {
        location: selectedReplies.toString(),
        beds: beds,
        // baths: array[2].charAt(0),
        price: max_price,
        target_market: target_markert,
        agentID:' '
      })
      .then((r) => {
        setMessages((prev) => [
          ...prev,
          {
            type: "listings",
            listings: r.data.listings,
            id: generateId(),
          },
        ]);
        const obj = {
          messag: d.text,
          type: "incoming",
        };
        setMessages((prev) => [
          ...prev,
          {
            type: "afterConversation",
            conversation: obj,
            id: generateId(),
          },
        ]);
        if (d.buttons) {
          setMessages((prev) => [
            ...prev,
            {
              type: "afterButtons",
              buttons: d.buttons,
              id: generateId(),
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="custom-chat-container">
        <ChatContainer>
          <MessageList>
            {messages.map((message) => (
              <>
                {message.type === "conversation" && (
                  <div className="cs-message-wrapper">
                    {message.conversation.type === "incoming" && (
                      <img className="cs-bot-img" src={Logo} alt="" />
                    )}
                    <Message
                      model={{
                        message: message.conversation.messag,
                        // sentTime: "15 mins ago",
                        sender: "Emily",
                        direction: message.conversation.type,
                        position: "single",
                      }}
                    ></Message>
                  </div>
                )}

                {message.type === "buttons" && (
                  <div className="cs-multiple-buttons-container">
                    <div className="cs-multiple-buttons-wrapper">
                      {message.buttons.map((btn) => {
                        const isSelected = selectedReplies.some(
                          (item) => item === btn.title
                        );
                        return (
                          <Button
                            key={btn.title}
                            className={isSelected ? "selected" : ""}
                            onClick={() =>
                              handleClickReply(btn.title, isSelected)
                            }
                          >
                            {isSelected && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M6 10.7801L3.68667 8.46672C3.56202 8.34207 3.39295 8.27204 3.21667 8.27204C3.04038 8.27204 2.87132 8.34207 2.74667 8.46672C2.62202 8.59137 2.55199 8.76043 2.55199 8.93672C2.55199 9.02401 2.56918 9.11044 2.60258 9.19108C2.63599 9.27172 2.68495 9.345 2.74667 9.40672L5.53333 12.1934C5.79333 12.4534 6.21333 12.4534 6.47333 12.1934L13.5267 5.14005C13.6513 5.0154 13.7213 4.84634 13.7213 4.67005C13.7213 4.49377 13.6513 4.3247 13.5267 4.20005C13.402 4.0754 13.233 4.00537 13.0567 4.00537C12.8804 4.00537 12.7113 4.0754 12.5867 4.20005L6 10.7801Z"
                                  fill="white"
                                />
                              </svg>
                            )}
                            {btn.title}
                          </Button>
                        );
                      })}
                    </div>
                    <button
                      className="cs-confirm-button"
                      style={{ opacity: selectedReplies.length > 0 ? 1 : 0.4 }}
                      onClick={confirmLocation}
                    >
                      Confirm
                    </button>
                  </div>
                )}

                {message.type === "otherButtons" && (
                  <div className="cs-options-wrapper">
                    {message.buttons.map((btn, index) => (
                      <Button
                        key={index}
                        className="cs-option-button"
                        onClick={() => otherButtons(btn.title, message.id)}
                      >
                        {btn.title}
                      </Button>
                    ))}
                  </div>
                )}

                {message.type === "listings" && (
                  <PropertiesCarousel
                    data={message.listings}
                    colorMode={colorMode}
                    handleFavorite={handleFavorite}
                    isSavedProperty={isSavedProperty}
                  />
                )}

                {message.type === "afterConversation" && (
                  <div className="cs-message-wrapper">
                    {message.conversation.type === "incoming" && (
                      <img className="cs-bot-img" src={Logo} alt="" />
                    )}
                    <Message
                      model={{
                        message: message.conversation.messag,
                        // sentTime: "15 mins ago",
                        sender: "Emily",
                        direction: message.conversation.type,
                        position: "single",
                      }}
                    ></Message>
                  </div>
                )}

                {message.type === "afterButtons" && (
                  <div className="cs-options-wrapper">
                    {message.buttons.map((btn, index) => (
                      <Button
                        key={index}
                        className="cs-option-button"
                        onClick={() => otherButtons(btn.title, message.id)}
                      >
                        {btn.title}
                      </Button>
                    ))}
                  </div>
                )}
              </>
            ))}
            {showpricedropdown&&(
              <div className="flex flex-col pb-3">
          <PriceDropDown />
          <div className="flex justify-center">

          <button  onClick={()=>fetchListings()} className="cursor-pointer text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Confirm</button>
          </div>

              </div>
            )}
          </MessageList>

          <MessageInput
            onSend={(e) => hadleNewMessage(e)}
            placeholder="Type a Message..."
            sendButton={true}
            attachButton={false}
            autoFocus
          />
        </ChatContainer>

        <br />
      </div>
    </>
  );
}
