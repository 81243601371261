export const BED_ROOMS = [
  {
    value: "any",
    label: "Any",
  },
  {
    value: 1,
    label: "1+",
  },
  {
    value: 2,
    label: "2+",
  },
  {
    value: 3,
    label: "3+",
  },
  {
    value: 4,
    label: "4+",
  },
  {
    value: 5,
    label: "5+",
  },
];

export const BED_ROOMS_EXACT = [
  {
    value: "studio",
    label: "Studio",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

export const BATH_ROOMS = [
  {
    value: "any",
    label: "Any",
  },
  {
    value: 1,
    label: "1+",
  },
  {
    value: 1.5,
    label: "1.5+",
  },
  {
    value: 2,
    label: "2+",
  },
  {
    value: 3,
    label: "3+",
  },
  {
    value: 4,
    label: "4+",
  },
];

export const SALES_MIN_PRICES = [
  { value: 0, label: "$0" },
  { value: 100000, label: "$100,000" },
  { value: 200000, label: "$200,000" },
  { value: 300000, label: "$300,000" },
  { value: 400000, label: "$400,000" },
  { value: 500000, label: "$500,000" },
  { value: 600000, label: "$600,000" },
  { value: 700000, label: "$700,000" },
  { value: 800000, label: "$800,000" },
  { value: 900000, label: "$900,000" },
];

export const SALES_MAX_PRICES = [
  { value: 500000, label: "$500,000" },
  { value: 600000, label: "$600,000" },
  { value: 700000, label: "$700,000" },
  { value: 800000, label: "$800,000" },
  { value: 900000, label: "$900,000" },
  { value: 1000000, label: "$1M" },
  { value: 1250000, label: "$1.25M" },
  { value: 1500000, label: "$1.5M" },
  { value: 1750000, label: "$1.75M" },
  { value: "any price", label: "Any Price" },
];

export const RENT_MIN_PRICES = [
  { value: 0, label: "$0" },
  { value: 200, label: "$200" },
  { value: 400, label: "$400" },
  { value: 600, label: "$600" },
  { value: 800, label: "$800" },
  { value: 1000, label: "$1000" },
  { value: 1200, label: "$1200" },
  { value: 1400, label: "$1400" },
  { value: 1600, label: "$1600" },
  { value: 1800, label: "$1800" },
];

export const RENT_MAX_PRICES = [
  { value: 0, label: "$0" },
  { value: 200, label: "$200" },
  { value: 400, label: "$400" },
  { value: 600, label: "$600" },
  { value: 800, label: "$800" },
  { value: 1000, label: "$1000" },
  { value: 1200, label: "$1200" },
  { value: 1400, label: "$1400" },
  { value: 1600, label: "$1600" },
  { value: "any price", label: "Any Price" },
];

export const saleSortOptions = [
  { value: "Homes_for_You", label: "For Sale" },
  { value: "Price_High_Low", label: "Price (High to Low)" },
  { value: "Price_Low_High", label: "Price (Low to High)" },
  { value: "Newest", label: "Newest" },
  { value: "Bedrooms", label: "Bedrooms" },
  { value: "Bathrooms", label: "Bathrooms" },
  { value: "Square_Feet", label: "Square Feet" },
  { value: "Lot_Size", label: "Lot Size" },
];

export const rentSortOptions = [
  { value: "Verified_Source", label: "Verified Source" },
  { value: "Payment_High_Low", label: "Payment (High to Low)" },
  { value: "Payment_Low_High", label: "Payment (Low to High)" },
  { value: "Newest", label: "Newest" },
  { value: "Bedrooms", label: "Bedrooms" },
  { value: "Bathrooms", label: "Bathrooms" },
  { value: "Square_Feet", label: "Square Feet" },
  { value: "Lot_Size", label: "Lot Size" },
];
