import { useState } from "react";
import { Form, Input, Space, Typography, Select, Modal, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  MailOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";

import { auth, db } from "../../../plugins/firebase";
import Logo from "../../../assets/images/logo.png";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { firestoreDB } from "../../../../src/plugins/firebase";

import { StyledForm, StyledButton, StyledSectionWrapper } from "../styles";

const { Title, Text } = Typography;

export const SignUp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onSignUp = async (values) => {
    setIsLoading(true);
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      const user = res.user;
      const userRole = values.role;
      const userPath = userRole === "agent" ? "agents/" : "users/";
      const username = values.firstName + values.lastName;

      await set(ref(db, userPath + user.uid), {
        firstName: values.firstName,
        lastName: values.lastName,
        username: username,
        email: values.email,
        role: userRole,
      });

      // Write to Firestore
      if (userRole === "agent") {
        console.log("write agent schema to firestore");
        const agentDoc = doc(firestoreDB, `agents/${username}-${user.uid}`);
        await setDoc(agentDoc, {
          agentID: user.uid, // The unique ID of the agent
          colorScheme: [], // The agent's preferred color scheme
          images: [], // An array to store the agent's images
          location: "", // The agent's location
          profileInfo: {
            username: username, // The agent's username
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            role: userRole,
          },
          myListings: [], // An array to store property listings
        });
      } else {
        console.log("write agent schema FAILED");
      }

      setIsLoading(false);
      setIsModalVisible(true); // Show the modal

      // Check the role and navigate to the appropriate URL
      setTimeout(() => {
        setIsModalVisible(false); // Hide the modal
        if (values.role === "agent") {
          window.location.href = "https://hh.househound.ai/";
        } else {
          navigate("/auth/login");
        }
      }, 2000); // 3 seconds delay
    } catch (e) {
      setError(e.code);
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-b from-houndBlueL1 to-houndBlueL4 min-h-screen w-full flex items-center justify-center">
      <Modal visible={isModalVisible} footer={null} closable={false}>
        <div style={{ textAlign: "center" }} className="pt-4">
          <Spin />
          <p className="text-xl pt-4">Saving your Credentials...</p>
        </div>
      </Modal>
      <StyledForm initialValues={{}} onFinish={onSignUp} autoComplete="off">
        <StyledSectionWrapper>
          <Link to="/" style={{ display: "flex" }}>
            <img src={Logo} alt="logo" />
          </Link>
          <h1 className="text-houndWhite text-4xl text-center ">
            Create an Account
          </h1>
          {/*  */}
          <Text className="form-label">First Name</Text>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "First name is required!",
              },
              {
                type: "text",
              },
            ]}
          >
            <Input
              suffix={<UserOutlined />}
              type="text"
              placeholder="First Name"
            />
          </Form.Item>
          {/*  */}
          <Text className="form-label">Last Name</Text>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Last name is required!",
              },
              {
                type: "text",
              },
            ]}
          >
            <Input
              suffix={<UserOutlined />}
              type="text"
              placeholder="Last Name"
            />
          </Form.Item>
          {/*  */}
          <Text className="form-label">Email</Text>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required!",
              },
              {
                type: "email",
                message: "Email is not a valid email!",
              },
            ]}
          >
            <Input suffix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          {/*  */}
          <Text className="form-label">Password</Text>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required!",
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              placeholder="Password"
            />
          </Form.Item>
        </StyledSectionWrapper>
        <StyledSectionWrapper>
          <Form.Item>
            <StyledButton
              htmlType="submit"
              loading={isLoading}
              type="primary"
              className="shadow-md shadow-gray-600"
            >
              Create Account
            </StyledButton>
          </Form.Item>
          <div className="flex justify-center text-lg select-none">
            Are you already registered?
            <Link to="/auth/login" className="font-medium underline ml-2">
              Login
            </Link>
          </div>
        </StyledSectionWrapper>
        {error && (
          <div className="text-red-500 text-center text-2xl">{error}</div>
        )}
      </StyledForm>
    </div>
  );
};
