import React, { useState } from "react";
import HeaderMay2024 from "../../layouts/Main/HeaderMay2024";
// import emailjs from "emailjs-com";

const Contact = () => {
  const [buttonText, setButtonText] = useState("Submit");

  //   const sendEmail = (e) => {
  //     e.preventDefault();

  //     emailjs
  //       .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
  //       .then(
  //         (result) => {
  //           console.log(result.text);
  //           setButtonText("Successfully Submitted");
  //         },
  //         (error) => {
  //           console.log(error.text);
  //         }
  //       );
  //   };

  return (
    <div className=" bg-gradient-to-b from-houndBlueL1 to-houndBlueL5 h-screen ">
      <HeaderMay2024 />
      <div className=" p-20 text-lg  flex flex-col  w-full place-items-center ">
        <h1>Contact Us</h1>
        <form
          //   onSubmit={sendEmail}
          className="flex flex-col gap-4 w-1/2"
        >
          <input type="hidden" name="contact_number" />
          <label>Name</label>
          <input type="text" name="user_name" required />
          <label>Email</label>
          <input type="email" name="user_email" required />
          <label>Message</label>
          <textarea name="message" required />
          <input type="submit" value={buttonText} />
        </form>
      </div>
    </div>
  );
};

export default Contact;
