import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  mode: "light",
};

const isLoading = (action) => {
  return ["theme/setMode/pending"].includes(action.type);
};

const isFinishLoading = (action) => {
  return ["theme/setMode/fulfilled", "theme/setMode/rejected"].includes(
    action.type
  );
};

const isRejected = (action) => {
  return ["theme/setMode/rejected"].includes(action.type);
};

export const setMode = createAsyncThunk(
  "theme/setMode",
  async (values, { rejectWithValue }) => {
    try {
      return {
        mode: values,
      };
    } catch (e) {
      return rejectWithValue({ message: e.message });
    }
  }
);

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(setMode.fulfilled, (state, action) => {
        state.mode = action.payload.mode;
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default themeSlice.reducer;
