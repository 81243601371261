import { Navigate, Routes, Route, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import { Main } from "./layouts/Main";
import { Auth } from "./layouts/Auth";
import { Home } from "./pages/Home";
import { Login } from "./pages/Auth/Login";
import { SignUp } from "./pages/Auth/SignUp";
import { ForgotPassword } from "./pages/Auth/ForgotPassword";
import { Subscription } from "./pages/Subscription";
import { CustomSearch } from "./pages/Search";
import { Favorite } from "./pages/Favorite";
import Privacy from "./pages/Home/Privacy";
import TermsAndConditions from "./pages/Home/TermsAndConditions";

import { Modal } from "antd";

import store from "./store";

import "./App.scss";
import "./map.scss";
import { Dashboard } from "./pages/Dashboard";
import { Property } from "./pages/Property";
import {Property as PropertyNew } from "./pages/Property/index2";
import React, { useEffect, useReducer } from "react";
import TermsAndConds from "./pages/Home/TermsAndConditions";
import Contact from "./pages/Home/Contact";
import About from "./pages/Home/About";
const initialState = {
  asking_login: false,
  listing: [],
  token: sessionStorage.getItem("my_token"),
  logged_in: false,
  redirect_login: false,
};

export const SavedListingContext = React.createContext(null);

function App() {
  const navigate = useNavigate();
  const reducer = (state, action) => {
    switch (action.type) {
      case "add":
        let updatedState = {
          ...state,
          listing: [...state.listing, action.payload],
        };
        // state.input = "";
        // console.log(updatedState);
        return updatedState;
      case "remove":
        return state - 1;
      case "auth_status":
        let updatedState1 = {
          ...state,
          asking_login: action.payload,
        };
        // state.input = "";
        // console.log(updatedState);
        return updatedState1;
      case "my_token":
        let updatedState3 = {
          ...state,
          token: action.payload,
        };
        // state.input = "";
        // console.log(updatedState);
        return updatedState3;
      case "redirect_login":
        // alert("hey");
        let updatedState2 = {
          ...state,
          redirect_login: action.payload,
        };
        // state.input = "";
        // console.log(updatedState);
        return updatedState2;
      case "logged_in":
        // alert("hey");
        let updatedState4 = {
          ...state,
          logged_in: action.payload,
        };
        // state.input = "";
        // console.log(updatedState);
        return updatedState4;

        if (action.payload) {
          navigate("/auth/login", { replace: true });
        } else {
          let updatedState1 = {
            ...state,
            asking_login: action.payload,
          };
          // state.input = "";
          // console.log(updatedState);
          return updatedState1;
          // return state.logged_in, action.payload;
        }
        return;
      case "reset":
        return [];
      default:
        alert("Default");
      // return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "auth_status", payload: false });
    if (state.redirect_login) {
      navigate("/auth/login", { replace: true });
    }
  }, [state.redirect_login]);

  // console.log(state)
  useEffect(() => {
    console.log(state);
  }, [state]);
  // const=redirectLogin();
  return (
    <Provider store={store}>
      <Modal
        open={state.asking_login}
        title="Do you want to save properties?"
        onOk={() => dispatch({ type: "redirect_login", payload: true })}
        onCancel={() => dispatch({ type: "auth_status", payload: false })}
      >
        In order to save properties please log in using your email.
      </Modal>
      <SavedListingContext.Provider
        value={{ state: state, dispatch: dispatch }}
      >
        {/* <h2>{state}</h2> */}
        {/* <button
          onClick={() => dispatch({ type: "add", payload: { name: "Hey" } })}
        >
          add
        </button> */}
        <Routes>
          <Route path="/main" element={<Main />}>
            <Route path="home" element={<Home />} />
            <Route path="termsandconditions" element={<TermsAndConditions />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />

            <Route path="search" element={<CustomSearch />} />
            <Route path="favorite" element={<Favorite />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="property/:zpid" element={<PropertyNew />} />
            <Route path="property2/:zpid" element={<Property />} />
            <Route path="subscription/:type" element={<Subscription />} />
            <Route index element={<Navigate to="home" replace />} />
            <Route path="*" element={<Navigate to="home" replace />} />
          </Route>
          <Route path="/auth" element={<Auth />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="signup" element={<SignUp />} />
            <Route index element={<Navigate to="login" replace />} />
            <Route path="*" element={<Navigate to="login" replace />} />
          </Route>
          <Route path="*" element={<Navigate to="/main/home" replace />} />
        </Routes>
      </SavedListingContext.Provider>
    </Provider>
  );
}

export default App;
