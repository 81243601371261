import React from "react";
import { Col, Row, Typography } from "antd";
import { HeartFilled } from "@ant-design/icons";

import { SearchResults, TitleText, NoSavedPropertiesWrapper } from "./styles";
import PropertyCard from "../../components/PropertyCard";
import { ReactComponent as NoSavedProperties } from "../../assets/images/no-saved-properties.svg";
import NoSavedPropertiesAnimation from "../../components/NoSavedPropertiesAnimation";

const { Text } = Typography;

const SavedProperties = ({
  isAuthenticated,
  savedProperties,
  colorMode,
  isMobile,
  removeSavedHome,
}) => {
  return (
    <SearchResults colorMode={colorMode}>
      {!isMobile && (
        <Row align="middle" justify="space-between">
          <Col>
            <TitleText>Saved Properties</TitleText>
          </Col>
        </Row>
      )}
      {isAuthenticated && savedProperties.length > 0 ? (
        <Row gutter={isMobile ? [16, 24] : [30, 30]}>
          {savedProperties.map((home) => (
            <Col xs={24} sm={12} md={12} lg={12} xl={8} key={home.zpid}>
              <PropertyCard
                key={home.zpid}
                property={home}
                colorMode={colorMode}
                showIcon={() => <HeartFilled />}
                handleFavorite={removeSavedHome}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <div className="flex justify-center items-center flex-col">
          <span className="text-center">
            {isAuthenticated ? (
              "No saved properties"
            ) : (
              <span>
                <strong className="pr-1">Log in</strong>
                to use access the "saved properties" feature.
              </span>
            )}
          </span>
          <NoSavedPropertiesAnimation />
        </div>
      )}
    </SearchResults>
  );
};

export default SavedProperties;
