import React, { useEffect } from "react";
import lottie from "lottie-web";
import animationData from "../assets/lottieAnimation.json";

export default function NoSavedPropertiesAnimation() {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("lottie-container"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  useEffect(() => {
    // Initialize animation here
    return () => lottie.destroy();
  }, []);

  return (
    <div id="lottie-container" style={{ width: 400, height: 400 }}>
      {/* {animationData} */}
    </div>
  );
}
