import { useMemo } from "react";
import { Typography } from "antd";

import { InfoSectionWrapper, TitleText } from "./styles";

const { Text } = Typography;

const InfoSection = ({
  name,
  openedChat,
  savedProperties,
  colorMode,
  isMobile,
}) => {
  const infoData = useMemo(
    () => [
      {
        count: openedChat,
        infoText: "Opened Chat",
      },
      {
        count: savedProperties,
        infoText: "Saved Properties",
      },
    ],
    [openedChat, savedProperties]
  );
  return (
    <InfoSectionWrapper colorMode={colorMode}>
      <TitleText className="name-text">
        {isMobile ? "Your daily summary" : `${name}! 😄`}
      </TitleText>
      <div className="info-wrapper">
        {infoData.map((info) => (
          <div key={info.infoText} className="info-item-wrapper">
            <Text className="count-text">{info.count}</Text>
            <Text className="info-text">{info.infoText}</Text>
          </div>
        ))}
      </div>
    </InfoSectionWrapper>
  );
};

export default InfoSection;
