import styled from "styled-components";
import LightModeBg from "../../assets/images/lightModeAuthBg.png";

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: ${(props) =>
    props.mode === "dark" ? "unset" : `url(${LightModeBg})`};
  background-repeat: no-repeat;
  background-color: ${(props) => (props.mode === "dark" ? "#0B0B0B" : "unset")};
  background-size: cover;

  @media screen and (max-width: 767px) {
    align-items: start;
    background: ${(props) =>
      props.mode === "dark"
        ? "#0B0B0B"
        : "linear-gradient(180deg, #659FCA 0%, rgba(173, 204, 227, 0.86) 70.10%, rgba(255, 255, 255, 0.70) 100%)"};
  }
`;
