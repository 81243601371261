import React from "react";
import ButtonStandard from "../components/ButtonStandard";

const Step = ({
  imgSrc,
  flexDirection,
  stepNumber,
  stepText,
  showButton = false,
  showTitle = false,
  step3,
}) => {
  return (
    <div
      className={`flex ${flexDirection} items-center justify-between gap-22 lg:gap-20`}
    >
      <div className='lg:text-left text-center flex flex-col space-y-6'>
        {showTitle && (
          <span className='text-6xl font-semibold'>{stepNumber}</span>
        )}
        <span className='text-3xl pb-6 font-thin'>{stepText}</span>
      </div>
      <div className='py-4'>
        <img
          className={`md:w-[740px] ${
            step3 ? "w-3/5" : "w-5/6"
          } mx-auto block object-contain rounded-3xl overflow-clip mb-10`}
          src={imgSrc}
          alt='Features 01'
          style={{ borderWidth: "10px", borderColor: "green" }}
        />
      </div>
    </div>
  );
};

export default Step;
