import { configureStore } from "@reduxjs/toolkit";

import theme from "./theme";
import stripe from "./stripe";
import favorite from "./favorite";
import auth from "./auth";

export default configureStore({
  reducer: {
    auth,
    theme,
    stripe,
    favorite,
  },
});
