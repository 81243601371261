import { Button, Checkbox, Modal, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

import { HeaderWrapper, VideoWrapper } from "./styles";
import Logo from "../../assets/images/logo.png";
import { useEffect, useRef } from "react";

const { Title } = Typography;

export const VideoPlayModal = ({ isOpen, onCancel }) => {
  const themeInfo = useSelector((state) => state.theme);
  const playerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      playerRef.current.seekTo(0);
    }
  }, [isOpen]);

  const handleGoToAI = () => {
    onCancel();
    navigate("/main/dashboard");
  };
  const handleShowThisTime = (e) => {
    window.localStorage.setItem("notShowVideo", e.target.checked);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      width={600}
      footer={[
        <Button onClick={onCancel} type="primary">
          OK
        </Button>,
      ]}
    >
      <>
        <HeaderWrapper>
          <Title
            level={4}
            style={{
              margin: 0,
              lineHeight: "20px",
              color: "#38caff",
              wordBreak: "keep-all",
            }}
          >
            House
          </Title>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 4,
              margin: "0 20px",
            }}
          >
            <img src={Logo} alt="" width="60px" height="50px" />
          </div>
          <Title
            level={4}
            style={{
              margin: 0,
              lineHeight: "20px",
              color: themeInfo.mode === "dark" ? "white" : "black",
              wordBreak: "keep-all",
            }}
          >
            HOUND
          </Title>
        </HeaderWrapper>
        <VideoWrapper>
          <ReactPlayer
            url={require("../../assets/video/1.mov")}
            controls
            width="400px"
            height="400px"
            muted
            playing
            ref={playerRef}
          />
        </VideoWrapper>
        <Checkbox onChange={handleShowThisTime}>
          Do not show this information again
        </Checkbox>
      </>
    </Modal>
  );
};
