import { Form } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  padding: 24px;
  max-width: 450px;
  width: 100%;
  background-color: #000000aa;

  h2.ant-typography {
    margin-bottom: 24px;
    text-align: center;
    color: #fff;
  }

  button[type='submit'] {
    width: 100%;
  }
`;
