import React from "react";
import { IconContext } from "react-icons";
import { IoIosArrowForward } from "react-icons/io";
import ButtonStandard from "./ButtonStandard";
import {
  IoSpeedometer,
  IoPerson,
  IoDocumentText,
  IoLocationOutline,
} from "react-icons/io5";

const DashboardView = ({
  imageSrc,
  icon,
  headingText,
  iconType,
  showButton = false,
}) => {
  let Icon;
  switch (iconType) {
    case "speedometer":
      Icon = IoSpeedometer;
      break;
    case "person":
      Icon = IoPerson;
      break;
    case "document":
      Icon = IoDocumentText;
      break;
    case "location":
      Icon = IoLocationOutline;
      break;
    default:
      Icon = IoDocumentText;
  }

  return (
    <div className="lg:max-w-4xl mx-auto p-6 flex flex-col lg:gap-8 text-center items-center text-white  justify-center">
      <Icon size={60} />
      <h1 className="lg:text-4xl text-3xl font-thin mt-4 mb-2 pb-10 lg:pb-0">
        {headingText}
      </h1>

      <img
        src={imageSrc}
        alt="Dashboard"
        className="lg:w-[1200px] lg:h-[600px] h-[580px] block  border-[10px] border-black rounded-3xl overflow-clip"
      />
    </div>
  );
};

export default DashboardView;
