import moment from "moment";

export const priceColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (record) => moment(record.date).format("MM/DD/YYYY"),
  },
  {
    title: "Event",
    dataIndex: "event",
    key: "event",
  },
  {
    title: "Price",
    key: "price",
    render: (record) => (
      <div>
        <div>
          {record.price}{" "}
          {record.priceChangeRate ? (
            <span
              style={{
                color: record.priceChangeRate < 0 ? "#a3000b" : "#055e16",
              }}
            >
              {record.priceChangeRate.toFixed(2)}%
            </span>
          ) : (
            ""
          )}
        </div>
        <div>${record.pricePerSquareFoot}</div>
      </div>
    ),
  },
];

export const taxColumns = [
  {
    title: "Year",
    key: "year",
    render: (record) => moment(record.time).year(),
  },
  {
    title: "Property taxes",
    key: "event",
    render: (record) => (
      <div>
        {record.taxPaid}{" "}
        {record.taxIncreaseRate ? (
          <span
            style={{
              color: record.taxIncreaseRate < 0 ? "#a3000b" : "#055e16",
              marginLeft: 5,
            }}
          >
            {record.taxIncreaseRate.toFixed(2)}%
          </span>
        ) : (
          ""
        )}
      </div>
    ),
  },
  {
    title: "Tax assessment",
    key: "tax",
    render: (record) => (
      <div>
        {record.value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        })}
        {record.valueIncreaseRate ? (
          <span
            style={{
              color: record.valueIncreaseRate < 0 ? "#a3000b" : "#055e16",
              marginLeft: 5,
            }}
          >
            {record.valueIncreaseRate.toFixed(2)}%
          </span>
        ) : (
          ""
        )}
      </div>
    ),
  },
];
