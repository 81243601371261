import {
  InfoContainer,
  TitleText,
  DescriptionText,
  Price,
  RoomsInfoWrapper,
  RoomInfo,
} from "./styles";
import { ReactComponent as BedIcon } from "../../assets/images/bed.svg";
import { ReactComponent as BathIcon } from "../../assets/images/bath.svg";

import { CustomPropertyModal } from "./styles";

export const PropertyModal = ({ open, colorMode, data, onCancel }) => {
  return (
    <CustomPropertyModal
      open={open}
      title="Listing Details"
      footer={null}
      wrapClassName={PropertyModal}
      maskClosable={true}
      onCancel={onCancel}
      width={600}
      colorMode={colorMode}
    >
      {data && (
        <>
          <img className="property-image" src={data.photo_url} alt="property" />
          <div className="property-info-wrapper">
            <InfoContainer>
              <Price className="property-price" colorMode={colorMode}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(data.price)}
              </Price>
              <RoomsInfoWrapper>
                <RoomInfo colorMode={colorMode} className="room-count">
                  {data.beds} <BedIcon />
                </RoomInfo>
                <RoomInfo colorMode={colorMode} className="room-count">
                  {data.baths} <BathIcon />
                </RoomInfo>
              </RoomsInfoWrapper>
            </InfoContainer>
            <TitleText className="property-address" colorMode={colorMode}>
              {data.title}
            </TitleText>
            <DescriptionText colorMode={colorMode}>
              {data.description}
            </DescriptionText>
          </div>
        </>
      )}
    </CustomPropertyModal>
  );
};
