import styled from "styled-components";
import { Button, Tabs, Typography } from "antd";
const { Title } = Typography;

export const DashboardPage = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  margin: auto;

  @media screen and (max-width: 960px) {
    height: calc(100vh - 103px);
    padding: 0 16px;
  }
`;

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  align-items: center;
  justify-content: center;

  @media screen and (max-width: 960px) {
    gap: 30px;
    height: 100%;
  }
`;

export const ChatbotWrapper = styled.div`
  width: 100%;

  height: 460px;
  border-radius: 40px;
  background: ${(props) =>
    props.colorMode === "dark"
      ? "linear-gradient(180deg, #1B1B1B 0%, #4E4E4E 100%)"
      : "linear-gradient(180deg, #000 0%, #3C3C3C 100%)"};
  display: flex;

  .chat-section {
    position: relative;
    flex: 1;
    width: 100%;
    overflow: hidden;
    border-radius: 40px;
    background: ${(props) =>
      props.colorMode === "dark"
        ? "#1F1F1F"
        : "linear-gradient(134deg, #FFF 0%, rgba(255, 255, 255, 0.90) 54.48%, rgba(255, 255, 255, 0.80) 100%)"};
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3),
      0px 4px 9px 0px rgba(0, 0, 0, 0.29), 0px 16px 16px 0px rgba(0, 0, 0, 0.26),
      0px 36px 21px 0px rgba(0, 0, 0, 0.15),
      0px 63px 25px 0px rgba(0, 0, 0, 0.04),
      0px 99px 28px 0px rgba(0, 0, 0, 0.01);
  }

  .control-section {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 26px 26px 20px;
  }
`;

export const StyledFloatButton = styled(Button)`
  background: #545454;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5),
    0px 1px 3px 0px rgba(0, 0, 0, 0.49), 0px 5px 5px 0px rgba(0, 0, 0, 0.43),
    0px 12px 7px 0px rgba(0, 0, 0, 0.25), 0px 21px 9px 0px rgba(0, 0, 0, 0.07),
    0px 33px 9px 0px rgba(0, 0, 0, 0.01);
  width: 45px !important;
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 960px) {
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
    min-height: 30px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const SpinWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const TitleText = styled(Title)`
  font-size: 20px !important;
  font-weight: 600;
  text-align: center;
  margin: 0 !important;
`;

export const SavedSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  position: relative;
`;

export const SearchResults = styled.div`
  flex: 1;
  border-radius: 40px;
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: ${(props) =>
    props.colorMode === "dark"
      ? "#1F1F1F"
      : "linear-gradient(134deg, #FFF 0%, rgba(255, 255, 255, 0.80) 47.92%, rgba(255, 255, 255, 0.90) 100%)"};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px 4px 9px 0px rgba(0, 0, 0, 0.29), 0px 17px 17px 0px rgba(0, 0, 0, 0.26),
    0px 38px 23px 0px rgba(0, 0, 0, 0.15), 0px 68px 27px 0px rgba(0, 0, 0, 0.04),
    0px 107px 30px 0px rgba(0, 0, 0, 0.01);

  @media screen and (max-width: 960px) {
    background: transparent;
    box-shadow: none;
    padding: 24px 0;
  }
`;

// export const NoSavedPropertiesWrapper = styled.div`
//   // flex: 1;
//   // display: flex;
//   // flex-direction: column;
//   // align-items: center;
//   // justify-content: center;
//   // gap: 36px;

//   // .ant-typography {
//   //   font-size: 18px;
//   // }
// `;

export const InfoSectionWrapper = styled.div`
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${(props) =>
    props.colorMode === "dark"
      ? "#1F1F1F"
      : "linear-gradient(134deg, #FFF 0%, rgba(255, 255, 255, 0.80) 47.92%, rgba(255, 255, 255, 0.90) 100%)"};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px 4px 9px 0px rgba(0, 0, 0, 0.29), 0px 17px 17px 0px rgba(0, 0, 0, 0.26),
    0px 38px 23px 0px rgba(0, 0, 0, 0.15), 0px 68px 27px 0px rgba(0, 0, 0, 0.04),
    0px 107px 30px 0px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  padding: 32px 28px;
  height: fit-content;

  .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .info-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: ${(props) =>
      props.colorMode === "dark" ? "rgba(46, 46, 46, 0.3)" : "#FFF"};
    padding: 26px 60px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3),
      0px 1px 2px 0px rgba(0, 0, 0, 0.29), 0px 3px 3px 0px rgba(0, 0, 0, 0.26),
      0px 7px 4px 0px rgba(0, 0, 0, 0.15), 0px 12px 5px 0px rgba(0, 0, 0, 0.04),
      0px 18px 5px 0px rgba(0, 0, 0, 0.01);
    border-radius: 15px;

    .count-text {
      font-size: 40px;
      font-weight: 600;
      color: #659fca;
    }

    .info-text {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 960px) {
    padding: 20px 18px;
    gap: 14px;
    border-radius: 20px;
    background: ${(props) =>
      props.colorMode === "dark"
        ? "#1F1F1F"
        : "linear-gradient(133deg,#fff 0%, rgba(255, 255, 255, 0.9) 46.35%, rgba(255, 255, 255, 0.8) 100%"});

    .name-text {
      text-align: left;
      font-size: 16px !important;
    }

    .info-wrapper {
      flex-direction: row;
      gap: 32px;
      .info-item-wrapper {
        background: transparent;
        box-shadow: none;
        flex-direction: row;
        gap: 8px;
        padding: 0;

        .count-text {
          font-size: 25px;
          line-height: 30px;
        }

        .info-text {
          font-size: 14px;
        }
      }
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  padding: 0 4px;
  height: 100%;
  overflow: auto;
  .ant-tabs-content {
    .ant-tabs-tabpane {
      height: calc(100% - 50px);
    }
  }
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-tab {
      padding: 0;
      color: #797979;
      font-size: 13px;
      font-weight: 500;

      &:hover {
        color: #fff;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      padding: 6px 12px;
      border-radius: 60px;
      background: linear-gradient(94deg, #000 0%, #3c3c3c 100%);
      color: #fff;
    }

    .ant-tabs-ink-bar {
      display: none;
    }
    &::before {
      display: none;
    }
  }
`;

export const MobileControlsWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 50%;
  border-radius: 0px 15px 15px 0px;
  background: ${(props) =>
    props.colorMode === "dark" ? "#393939" : "#d9d9d9"};
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.3);
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  z-index: 1000;
  max-height: 62px;
  overflow: hidden;
  transition: max-height 0.2s;

  .overlay {
    width: 100vw;
    height: 0;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
  }

  &.expanded {
    max-height: 1000px;

    .overlay {
      height: 100vh;
    }
  }
`;
