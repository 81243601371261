import { Button, Checkbox, Modal, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { HeaderWrapper } from "./styles";
import Logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";

const { Title } = Typography;

export const SuggestModal = ({ isOpen, onCancel }) => {
  const themeInfo = useSelector((state) => state.theme);

  const navigate = useNavigate();
  const handleGoToAI = () => {
    onCancel();
    navigate("/main/dashboard");
  };
  const handleShowThisTime = (e) => {
    window.localStorage.setItem("notShowAgain", e.target.checked);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      width={400}
      footer={[
        <Button onClick={onCancel} type="primary">
          OK
        </Button>,
      ]}
    >
      <>
        <HeaderWrapper>
          <Title
            level={4}
            style={{
              margin: 0,
              lineHeight: "20px",
              color: "#38caff",
              wordBreak: "keep-all",
            }}
          >
            House
          </Title>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 4,
              margin: "0 20px",
            }}
          >
            <img src={Logo} alt="" width="60px" height="50px" />
          </div>
          <Title
            level={4}
            style={{
              margin: 0,
              lineHeight: "20px",
              color: themeInfo.mode === "dark" ? "white" : "black",
              wordBreak: "keep-all",
            }}
          >
            HOUND
          </Title>
        </HeaderWrapper>
        <Title level={3}>
          Want to see more properties? Use our{" "}
          <span
            onClick={() => handleGoToAI()}
            style={{ color: "#6ccff6", cursor: "pointer" }}
          >
            AI Search
          </span>{" "}
          to get access to homes that only real estate agents have access to!
        </Title>
        <Checkbox onChange={handleShowThisTime}>
          Do not show this information again
        </Checkbox>
      </>
    </Modal>
  );
};
