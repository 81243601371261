import styled from "styled-components";
import { Modal } from "antd";

export const StyledPropertyCard = styled.div`
  height: 100%;
  border-radius: 18.408px;
  background: ${(props) =>
    props.colorMode === "dark" ? "rgba(117, 117, 117, 0.3)" : "#f2f2f2"};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px 0.9751583337783813px 1.9503166675567627px 0px rgba(0, 0, 0, 0.29),
    0px 3.9006333351135254px 3.9006333351135254px 0px rgba(0, 0, 0, 0.26),
    0px 9.751582145690918px 5.850950241088867px 0px rgba(0, 0, 0, 0.15),
    0px 16.57769203186035px 6.826107978820801px 0px rgba(0, 0, 0, 0.04),
    0px 26.329273223876953px 6.826107978820801px 0px rgba(0, 0, 0, 0.01);
  width: 220px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const FavoriteButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PropertyImage = styled.img`
  width: 100%;
  height: 96px;
  object-fit: cover;
`;

export const InfoWrapper = styled.div`
  padding: 8px 8px 12px 8px;
`;

export const TitleText = styled.div`
  color: ${(props) => (props.colorMode === "dark" ? "#ffffff" : "#1E1E1E")};
  font-size: 14px;
  font-weight: 600;
`;

export const DescriptionText = styled.div`
  margin: 4px 0px;
  color: #5f5f5f;
  color: ${(props) => (props.colorMode === "dark" ? "#dddddd" : "#5f5f5f")};
  font-size: 12px;
  font-weight: 400;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const RoomsInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RoomInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => (props.colorMode === "dark" ? "#dddddd" : "#5f5f5f")};
  font-size: 14px;
`;

export const Price = styled.div`
  color: ${(props) => (props.colorMode === "dark" ? "#ffffff" : "#1E1E1E")};
  font-size: 13px;
  font-weight: 600;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 2px;
  margin: auto auto 12px auto;
  width: fit-content;
  padding-top: 8px;
`;

export const StyledButton = styled.div`
  border-radius: 6px;
  background: #659fca;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px 24px;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 0px rgba(0, 0, 0, 0.29), 0px 6px 6px 0px rgba(0, 0, 0, 0.26),
    0px 13px 8px 0px rgba(0, 0, 0, 0.15), 0px 23px 9px 0px rgba(0, 0, 0, 0.04),
    0px 36px 10px 0px rgba(0, 0, 0, 0.01);
`;

export const CustomPropertyModal = styled(Modal)`
  .ant-modal-content {
    padding: 40px 0 0 0;
    border-radius: 20px;
    overflow: hidden;
    background-color: rgba(117, 117, 117, 0.3)
    background: ${(props) =>
      props.colorMode === "dark"
        ? "rgba(117, 117, 117, 0.3)"
        : "linear-gradient(128deg, #FFF 2.99%, rgba(255, 255, 255, 0.90) 46.25%, rgba(255, 255, 255, 0.80) 96.32%)"};
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.29),
      0px 12px 12px 0px rgba(0, 0, 0, 0.26),
      0px 28px 17px 0px rgba(0, 0, 0, 0.15),
      0px 49px 20px 0px rgba(0, 0, 0, 0.04),
      0px 77px 22px 0px rgba(0, 0, 0, 0.01);
  }

  .ant-modal-close {
    top: 42px;
  }

  .ant-modal-header {
    background: transparent;
    padding-left: 30px;
    margin-bottom: 20px;

    .ant-modal-title {
      color: ${(props) => (props.colorMode === "dark" ? "#ffffff" : "#1E1E1E")};
      font-size: 30px;
    }
  }

  .ant-modal-body {
    .property-image {
      width: 100%;
      height: 325px;
      object-fit: cover;
    }

    .property-info-wrapper {
      padding: 16px 30px;

      .property-price {
        font-size: 30px;
      }

      .room-count {
        font-size: 20px;
      }

      .property-address {
        margin: 4px 0;
        font-size: 20px;
      }
    }
  }
`;
