import React from "react";
import HHLogo from "../../assets/HHLogos/HHLogo.png"; // Adjust the path as necessary

const Logo = () => {
  return (
    <div>
      <a href="main/home" className="flex items-center">
        <img src={HHLogo} alt="HouseHound.AI Logo" className="h-10 mr-2" />
      </a>
    </div>
  );
};

export default Logo;
