import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  isAuthenticated: false,
  loading: false,
  userInfo: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    removeToken: (state) => {
      state.token = null;
      state.userInfo = null;
      state.isAuthenticated = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setToken, removeToken, setLoading, setUserInfo } = authSlice.actions;

export const authenticate = (token) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // Save the token to AsyncStorage for persistence
    dispatch(setToken(token));
  } catch (error) {
    console.error("Authentication error:", error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // Remove the token from AsyncStorage
    dispatch(removeToken());
  } catch (error) {
    console.error("Logout error:", error);
  } finally {
    dispatch(setLoading(false));
  }
};

export default authSlice.reducer;
