import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Spin, Typography } from "antd";
import { SearchResults, SpinWrapper } from "./styles";
import { removeHome } from "../../store/favorite";
import { HeartFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

const { Title, Text } = Typography;

export const Favorite = () => {
  const dispatch = useDispatch();
  const favoriteState = useSelector((state) => state.favorite);
  const [finalHomes, setFinalHomes] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFavorite = (home) => {
    dispatch(removeHome(home));
  };
  useEffect(() => {
    handleLoadSavedHomes();
  }, []);

  const handleLoadSavedHomes = async () => {
    setLoading(true);
    const homes = [];
    for (var i = 0; i < favoriteState.savedHomes.length; i++) {
      const options = {
        method: "GET",
        url: "https://zillow-com1.p.rapidapi.com/property",
        params: {
          zpid: favoriteState.savedHomes[i],
        },
        headers: {
          "X-RapidAPI-Key":
            "8ed83f5685msh07680ee9a434eb3p1cc0f6jsnf0f2f90011da",
          "X-RapidAPI-Host": "zillow-com1.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.request(options);
        if (response.data) {
          homes.push(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    setLoading(false);
    setFinalHomes(homes);
  };
  
  return (
    <div className="page-container">
      <Title level={1}>Saved homes</Title>
      {loading && (
        <SpinWrapper>
          <Spin size="large" />
        </SpinWrapper>
      )}
      <SearchResults>
        {finalHomes.length > 0 && (
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            {finalHomes.map((home) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={home.zpid}>
                <div key={home.zpid} className="result-home">
                  <div
                    className="result-home-favorite"
                    onClick={() => handleFavorite(home.zpid)}
                  >
                    <Button shape="circle" icon={<HeartFilled />} />
                  </div>
                  <div className="result-home-image">
                    <img
                      src={home.imgSrc}
                      width="300px"
                      height="180px"
                      alt=""
                    />
                  </div>
                  <div className="result-home-info">
                    <div className="result-home-info-top">
                      <Text className="result-home-info-top-price">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(home.price)}
                      </Text>
                      <div className="result-home-info-top-rooms">
                        <Text className="result-home-info-top-bed">
                          {home.bedrooms} <span>BedRooms</span>
                        </Text>
                        <Text className="result-home-info-top-bath">
                          {home.bathrooms} <span>BathRooms</span>
                        </Text>
                      </div>
                    </div>
                    <div className="result-home-info-bottom">
                      <Text>{`${home.address.streetAddress} ${home.address.city} ${home.address.state} ${home.address.zipcode}`}</Text>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </SearchResults>
    </div>
  );
};
