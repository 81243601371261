import { useContext, useState } from "react";
import { Form, Input, Typography, Space } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import { signInWithEmailAndPassword } from "firebase/auth";
import { ref, onValue } from "firebase/database";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import SessionService from "../../../services/SessionService";
import { auth, db, signInWithGooglePopup } from "../../../plugins/firebase";
import { setToken, setUserInfo } from "../../../store/auth";

import "../../../tailwind.css";

import {
  StyledForm,
  StyledButton,
  StyledSocialWrapper,
  StyledSocialButtons,
  StyledSocialButton,
  StyledSectionWrapper,
} from "../styles";
import { loadHomes } from "../../../store/favorite";
import Logo from "../../../assets/images/logo.png";
import AppleLogo from "../../../assets/images/apple-logo.png";
import GoogleLogo from "../../../assets/images/google-logo.png";
import FacebookLogo from "../../../assets/images/facebook-icon.svg";
import { SavedListingContext } from "../../../App";

const { Title, Text } = Typography;

export const Login = () => {
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(SavedListingContext);

  const onLogin = async (values) => {
    setLoggingIn(true);
    try {
      const userInfo = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const token = await auth.currentUser.getIdToken();
      const userRef = ref(db, "users/" + userInfo.user.uid);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        SessionService.setAccessToken(token);
        SessionService.setUserData({
          ...data,
          uid: userInfo.user.uid,
        });
        sessionStorage.setItem("my_token", token);
        sessionStorage.setItem("logged_in", true);
        context.dispatch({ type: "auth_status", payload: false });
        context.dispatch({ type: "logged_in", payload: true });
        context.dispatch({ type: "my_token", payload: token });

        dispatch(
          setUserInfo({
            ...data,
            uid: userInfo.user.uid,
          })
        );
        dispatch(setToken(token));

        const payload = {
          savedHomes: data.savedHomes,
        };
        dispatch(loadHomes(payload));
        setError("");
        if (SessionService.getPreviousPath()) {
          navigate(SessionService.getPreviousPath());
        } else {
          navigate("/");
        }
        // window.location.href = SessionService.getPreviousUrl() ?? "/";
      });
    } catch (e) {
      setError(e.code);
    }
    setLoggingIn(false);
  };

  const handleSignInGoogle = async () => {
    setLoggingIn(true);
    setError("");
    try {
      const response = await signInWithGooglePopup();
      if (response.user) {
        SessionService.setAccessToken(response.user.accessToken);
        SessionService.setUserData({
          email: response.user.email,
          displayName: response.user.displayName,
        });
        dispatch(setToken(response.user.accessToken));
        dispatch(
          setUserInfo({
            email: response.user.email,
            displayName: response.user.displayName,
          })
        );
        setError("");
      }
    } catch (e) {
      if (e.code === "auth/popup-closed-by-user") {
        setError("Authentication popup cancelled");
      } else {
        setError("Failed");
      }
    }
    setLoggingIn(false);
  };

  return (
    <div className="bg-gradient-to-b from-houndBlueL1 to-houndBlueL4 min-h-screen w-full flex items-center justify-center">
      <StyledForm initialValues={{}} onFinish={onLogin} autoComplete="off">
        <StyledSectionWrapper>
          <Link to="/" style={{ display: "flex" }}>
            <img src={Logo} alt="logo" />
          </Link>
          <h1 className="text-houndWhite text-4xl text-center capitalize">
            Login to start looking for your dream home
          </h1>
          {/*  */}
          <Text className="form-label">Email</Text>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required!",
              },
              {
                type: "email",
                message: "Email is not a valid email!",
              },
            ]}
          >
            <Input placeholder="Email address" suffix={<MailOutlined />} />
          </Form.Item>
          {/*  */}
          <Text className="form-label">Password</Text>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required!",
              },
            ]}
          >
            <Input.Password
              placeholder="input password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          {/* Forgot PW text */}
          <Space
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: 30,
            }}
          >
            <Link to="/auth/forgot-password">Forgot Password?</Link>
          </Space>
        </StyledSectionWrapper>
        <StyledSectionWrapper>
          {/* Log in Submit Button */}
          <Form.Item>
            <StyledButton
              htmlType="submit"
              loading={isLoggingIn}
              type="primary"
              className="shadow-md shadow-gray-600"
            >
              Log In
            </StyledButton>
          </Form.Item>
          {/* <Link
            to="https://hh.househound.ai/"
            className="rounded-md flex justify-center  mb-6 p-4 bg-houndBlueD3 hover:bg-houndBlue shadow-md shadow-gray-600 transition-colors duration-500"
          >
            <span className="text-xl text-houndWhite select-none">
              Agent Dashboard
            </span>
          </Link> */}
          {/* Sign Up */}
          <div className="flex justify-center text-lg">
            Don't have an account yet?
            <Link to="/auth/signup" className="font-medium underline ml-2">
              Register now
            </Link>
          </div>

          <Link to="/" className="flex justify-center text-lg underline pt-2">
            Back to Home Page
          </Link>
          {/* Social Links */}
          {/* <StyledSocialWrapper align="center">
              <Text>or login with</Text>
              <StyledSocialButtons>
                <StyledSocialButton onClick={handleSignInGoogle}>
                  <img src={GoogleLogo} alt="google logo" />
                </StyledSocialButton>
                <StyledSocialButton>
                  <img src={AppleLogo} alt="apple logo" />
                </StyledSocialButton>
                <StyledSocialButton>
                  <img src={FacebookLogo} alt="facebook logo" />
                </StyledSocialButton>
              </StyledSocialButtons>
            </StyledSocialWrapper> */}
        </StyledSectionWrapper>
        {error && (
          <div style={{ color: "red", textAlign: "center", fontSize: 20 }}>
            {error}
          </div>
        )}
      </StyledForm>
    </div>
  );
};
