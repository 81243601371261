import React from "react";
import HeaderMay2024 from "../../layouts/Main/HeaderMay2024";

const About = () => {
  return (
    <div>
      <HeaderMay2024 />
      <div className=" p-20 text-lg  bg-gradient-to-b from-houndBlueL1 to-houndBlueL5  flex flex-col ">
        <h1>About House Hound</h1>
        <p>Welcome to House Hound!</p>
        <h2>1. Our Story</h2>
        <p>
          House Hound was founded in Chicago with the aim of revolutionizing the
          real estate industry. We leverage AI technology to provide a seamless
          and efficient real estate experience.
        </p>
        <h2>2. Our Mission</h2>
        <p>
          Our mission is to empower users with intelligent tools that simplify
          the home buying and selling process.
        </p>
        <h2>3. Our Vision</h2>
        <p>
          We envision a world where real estate transactions are effortless,
          transparent, and accessible to everyone.
        </p>
        <h2>4. Our Team</h2>
        <p>
          Our team is composed of dedicated professionals with diverse
          backgrounds in technology, real estate, and customer service.
        </p>
        <h2>5. Contact Us</h2>
        <p>
          We'd love to hear from you! Feel free to reach out to us with any
          questions, comments, or feedback.
        </p>
      </div>
    </div>
  );
};

export default About;
