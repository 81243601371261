// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBj--dtWp2w8eRVMPTGubXS7g1f71GIu1k",
  authDomain: "house-hound-eeb03.firebaseapp.com",
  databaseURL: "https://house-hound-eeb03-default-rtdb.firebaseio.com",
  projectId: "house-hound-eeb03",
  storageBucket: "house-hound-eeb03.appspot.com",
  messagingSenderId: "483116182874",
  appId: "1:483116182874:web:87d8d267c7749ad3771763",
  measurementId: "G-G2DGMEZ9XY",
  // apiKey: "AIzaSyDglUBOGzeC6ir9kEOxaBbtCNPTwVvDy8g",
  // authDomain: "test-app-ba51e.firebaseapp.com",
  // projectId: "test-app-ba51e",
  // storageBucket: "test-app-ba51e.appspot.com",
  // messagingSenderId: "781895283157",
  // appId: "1:781895283157:web:5bb30c13a378e1f2238d5c",
  // measurementId: "G-2N0JFP0T0L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const firestoreDB = getFirestore(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account ",
});
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export default app;
