import React from "react";
import HeaderMay2024 from "../../layouts/Main/HeaderMay2024";

const TermsAndConditions = () => {
  return (
    <div>
      <HeaderMay2024 />
      <div className=" p-20 text-lg  bg-gradient-to-b from-houndBlueL1 to-houndBlueL5  flex flex-col ">
        <h1>Terms and Conditions</h1>
        <p>Welcome to House Hound!</p>
        <h2>1. Introduction</h2>
        <p>
          These Terms and Conditions govern your use of our service, provided by
          House Hound.
        </p>
        <h2>2. Acceptance of Terms</h2>
        <p>
          By using our service, you agree to these terms. If you disagree with
          any part of the terms, you may not use our service.
        </p>
        <h2>3. Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. Your continued
          use of our service after changes constitutes your acceptance of the
          new terms.
        </p>
        <h2>4. Our Services</h2>
        <p>
          House Hound provides a real estate AI agent service. We do not
          guarantee the accuracy, completeness, or usefulness of any information
          on the service.
        </p>
        <h2>5. Limitation of Liability</h2>
        <p>
          In no event will House Hound, or its suppliers or licensors, be liable
          with respect to any subject matter of this agreement under any
          contract, negligence, strict liability or other legal or equitable
          theory.
        </p>
        <h2>6. General Representation and Warranty</h2>
        <p>
          You represent and warrant that your use of the service will be in
          strict accordance with the House Hound Privacy Policy, with this
          Agreement and with all applicable laws and regulations.
        </p>
        <h2>7. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless House Hound, its contractors,
          and its licensors, and their respective directors, officers, employees
          and agents from and against any and all claims and expenses, including
          attorneys' fees, arising out of your use of the service, including but
          not limited to your violation of this Agreement.
        </p>
        <h2>8. Miscellaneous</h2>
        <p>
          This Agreement constitutes the entire agreement between House Hound
          and you concerning the subject matter hereof, and they may only be
          modified by a written amendment signed by an authorized executive of
          House Hound, or by the posting by House Hound of a revised version.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
