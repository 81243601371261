import React from "react";

import HeroImage from "../../assets/images/hero-imageBlank.png";
import Aos from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import ButtonStandard from "../ButtonStandard";

export default function Hero({ isMobile }) {
  // const defaultOptions = {
  //   reverse: false, // reverse the tilt direction
  //   max: 35, // max tilt rotation (degrees)
  //   perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  //   scale: 1.1, // 2 = 200%, 1.5 = 150%, etc..
  //   speed: 1000, // Speed of the enter/exit transition
  //   transition: true, // Set a transition on enter/exit.
  //   axis: null, // What axis should be disabled. Can be X or Y.
  //   reset: true, // If the tilt effect has to be reset on exit.
  //   easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  // };

  React.useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className=' flex flex-col lg:flex-row w-full items-center lg:pl-20'>
      <div className='flex flex-col font-semibold text-white text-center lg:text-left  gap-2 lg:mb-0'>
        <div className='flex flex-col w-full'>
          <span className=' lg:text-[75px] text-5xl' data-aos='fade-up'>
            Become a
          </span>
          <span
            className=' lg:text-[100px] text-5xl font-semibold   text-houndBlue'
            data-aos='fade-up'
          >
            Super Agent
          </span>
        </div>
        {isMobile && (
          <div className='flex justify-center items-center'>
            <img
              src={HeroImage}
              alt=''
              data-aos='fade-left'
              data-aos-delay='100'
              className={`w-11/12 py-10 `}
            />
          </div>
        )}
        <span className='text-2xl font-thin pb-14 lg:my-10 px-6 lg:px-0 '>
          Bring your clients into an AI-assisted workspace, where you, your
          client and House Hound AI can research properties and schedule
          showings together. Real Estate has never been this productive.
        </span>
        <ButtonStandard
          href='https://hh.househound.ai/signup'
          text='Create your Virtual Assistant Now'
          textSize={isMobile ? "text-lg" : "text-2xl"}
          py={isMobile ? "py-4" : "py-6"}
          px={isMobile ? "px-4" : "px-16"}
          width={"w-72"}
        />
      </div>
      {!isMobile && (
        <img
          src={HeroImage}
          alt=''
          data-aos='fade-left'
          data-aos-delay='100'
          className={`rounded-3xl w-[500px] h-[500px] ml-28`}
        />
      )}
    </div>
  );
}
