import React from "react";
import "./footer.css";
// import a from "next/a";

import { FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa"; // Import the icons
import HHLogo from "../../../assets/HHLogos/HHLogo.png"; // Adjust the path as necessary

export default function Footer() {
  return (
    <footer className="bg-houndBlueD4 text-white w-full">
      <div className="py-12 px-10">
        <div className=" mx-auto mb-6 md:mb-0">
          {/* Top area: Blocks */}
          <div className="flex flex-col  justify-between">
            {/* 1st block */}
            <div className="flex flex-col justify-center items-center md:items-start md:gap-6 gap-12 pb-6 ">
              <img
                src={HHLogo}
                alt="HouseHound.AI Logo"
                className="h-10 mr-2"
              />
              <span className="text-white text-center text-2xl">
                Become a{" "}
                <span className="text-houndBlueL5 font-semibold">
                  Super Agent
                </span>
              </span>
            </div>
            <div className="md:text-sm text-start text-lg mb-10">
              <div className="md:grid md:grid-cols-3 gap-4 flex flex-col">
                <div className="space-y-4 text-lg">
                  <div
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    className="text-white w-fit cursor-pointer  transition duration-300 ease-in-out hover:text-houndBlue"
                  >
                    Home
                  </div>
                  <div className="mb-1">
                    <a
                      href="/main/dashboard"
                      className="text-white  transition duration-300 ease-in-out hover:text-houndBlue"
                    >
                      Chat Search
                    </a>
                  </div>
                  <div className="mb-1">
                    <a
                      href="/main/search"
                      className="text-white  transition duration-300 ease-in-out hover:text-houndBlue"
                    >
                      Map Search
                    </a>
                  </div>
                </div>
                <div className="space-y-4 text-lg">
                  {" "}
                  <div className="mb-1">
                    <a
                      href="/main/privacy"
                      className="text-white  transition duration-300 ease-in-out hover:text-houndBlue"
                    >
                      Privacy
                    </a>
                  </div>{" "}
                  <div className="mb-1">
                    <a
                      href="/main/termsandconditions"
                      className="text-white  transition duration-300 ease-in-out hover:text-houndBlue"
                    >
                      Terms and Conditions
                    </a>
                  </div>
                  <div className="mb-1">
                    <a
                      href="/main/contact"
                      className="text-white  transition duration-300 ease-in-out hover:text-houndBlue"
                    >
                      Contact
                    </a>
                  </div>
                </div>

                <div className="space-y-4 text-lg">
                  <div className="mb-1">
                    <a
                      href="/main/about"
                      className="text-white  transition duration-300 ease-in-out hover:text-houndBlue"
                    >
                      About
                    </a>
                  </div>

                  <div className="mb-1">
                    <a
                      href="https://hh.househound.ai/login"
                      className="text-white  transition duration-300 ease-in-out hover:text-houndBlue"
                    >
                      Login
                    </a>
                  </div>
                  <div className="mb-1">
                    <a
                      href="https://hh.househound.ai/signup"
                      className="text-white  transition duration-300 ease-in-out hover:text-houndBlue"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-10 lg:gap-0 lg:flex-row justify-center w-full items-center  lg:justify-between  ">
          <div className="flex flex-row list-none gap-8 md:gap-4">
            <div className="md:ml-4">
              <a
                href="https://www.instagram.com/househound.ai/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-center items-center text-sky-400 bg-gray-800  hover:bg-300-600 rounded-full transition duration-150 ease-in-out hover:text-houndBlue"
                aria-label="Instagram"
              >
                <FaInstagram className="w-8 h-8" />{" "}
              </a>
            </div>
            <div className="md:ml-4">
              <a
                href="https://www.linkedin.com/company/house-hound/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-center items-center text-sky-400 bg-gray-800  hover:bg-300-600 rounded-full transition duration-150 ease-in-out hover:text-houndBlue"
                aria-label="LinkedIn"
              >
                <FaLinkedin className="w-8 h-8" />
              </a>
            </div>
            {/* <div className="md:ml-4">
              <a
                href="https://www.facebook.com/househound.ai/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-center items-center text-sky-400 bg-gray-800  hover:bg-300-600 rounded-full transition duration-150 ease-in-out hover:text-houndBlue"
                aria-label="Facebook"
              >
                <FaFacebook className="w-8 h-8" />
              </a>
            </div> */}
          </div>
          <div className="text-white text-lg md:mr-4 text-center ">
            2024 &copy;HouseHound.AI <br /> All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
