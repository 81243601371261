import React from "react";

const ButtonStandard = ({
  href,
  children,
  width = "w-1/2",
  text,
  mdtext = "md:text-3xl",
  px = "px-24",
  py = "py-3",
  textSize = "text-md",
}) => {
  return (
    <div>
      <a href={href}>
        <span
          className={`${py} ${width} ${px} ${textSize} ${mdtext} bg-gradient-to-r from-houndBlueL5 to-[#0157fe]
          rounded-full shadow-sm shadow-black hover:shadow mb-4  md:mb-0
          transition-all duration-300 whitespace-nowrap text-white font-thin `}
        >
          {text}
        </span>
      </a>
    </div>
  );
};

export default ButtonStandard;
