import { useState } from "react";
import { Button, Form, Input, Typography, Space, notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { auth } from "../../../plugins/firebase";

import { StyledForm } from "./styles";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const ForgotPassword = () => {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const onResetPassword = async (values) => {
    setSending(true);
    sendPasswordResetEmail(auth, values.email)
      .then(() => {
        api.success({
          message: "Email sent!",
          description: "Password reset email sent successfully",
          duration: 0,
        });
        setSent(true);
        setSending(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <StyledForm
      initialValues={{}}
      onFinish={onResetPassword}
      autoComplete="off"
    >
      {contextHolder}
      <Title level={2}>Reset Password</Title>
      {!sent && (
        <>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required!",
              },
              {
                type: "email",
                message: "Email is not a valid email!",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <Button htmlType="submit" loading={sending} type="primary">
                Reset password
              </Button>
            </Space>
          </Form.Item>
        </>
      )}
      {sent && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link
            to="/auth/login"
            style={{
              color: "white",
              borderRadius: 6,
              height: 32,
              background: "#1677ff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 85,
            }}
          >
            Login
          </Link>
        </div>
      )}
    </StyledForm>
  );
};
