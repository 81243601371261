import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import {
  Typography,
  Space,
  Select,
  Button,
  Popover,
  Row,
  Col,
  Spin,
  Pagination,
  Modal,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { onValue, ref, update } from "firebase/database";
import { auth, db } from "../../plugins/firebase";

import { GoogleApiWrapper, Map, Marker, Polygon } from "google-maps-react";
import {
  BATH_ROOMS,
  BED_ROOMS,
  BED_ROOMS_EXACT,
  RENT_MAX_PRICES,
  RENT_MIN_PRICES,
  SALES_MAX_PRICES,
  SALES_MIN_PRICES,
  rentSortOptions,
  saleSortOptions,
} from "./data";
import {
  SearchResultWrapper,
  SearchSectionWrapper,
  MapWrapper,
  SearchInputs,
  SearchAddress,
  SearchPopupButton,
  SearchResults,
  SpinWrapper,
  SearchPanel,
  StyledDrawingSwitch,
  SearchTopWrapper,
  ClearPolygon,
  StyledSortSelect,
} from "./styles";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { removeHome, saveHome, updateHome } from "../../store/favorite";
import { useNavigate } from "react-router-dom";
import { SuggestModal } from "../../components/SuggestModal";
import SessionService from "../../services/SessionService";
import { VideoPlayModal } from "../../components/VideoPlayModal";

import PropertyCard from "../../components/PropertyCard";
import { AppHeader } from "../../layouts/Main/AppHeader";
import HeaderMay2024 from "../../layouts/Main/HeaderMay2024";
import useWindowSize from "../../hooks/useWindowSize";

const { Title, Text } = Typography;

const Search = ({ google }) => {
  const { isMobile } = useWindowSize();

  const navigate = useNavigate();
  const introSteps = [
    {
      element: ".start-drawing-btn",
      intro: "Click to start drawing polygon on the map.",
      position: "bottom",
    },
    {
      element: ".map-wrapper",
      intro: "Click on the map several times to draw polygon",
      position: "right",
    },
    {
      element: ".stop-drawing-btn",
      intro: "Click to stop drawing polygon on the map.",
      position: "bottom",
    },
    {
      element: ".search-btn",
      intro: "Click to begin search properties in the polygon area.",
      position: "bottom",
    },
  ];
  const [introEnabled, setIntroEnabled] = useState(false);
  const {
    ready,
    value,
    suggestions: { status, data },
    clearSuggestions,
    setValue,
  } = usePlacesAutocomplete({ callbackName: "initMap" });
  const [hoveringHome, setHoveringHome] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [suggestOpen, setSuggestOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  // const [currentProperty, setCurrentProperty] = useState(null);
  // const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [isAskingLoginModal, setIsAskingLoginModal] = useState(false);
  const showAuthModal = () => {
    setIsAskingLoginModal(true);
  };
  const handleShowAuthModal = () => {
    setIsAskingLoginModal(false);
    navigate("/auth/login");
  };
  const handleHideAuthModal = () => {
    setIsAskingLoginModal(false);
  };
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const favoriteState = useSelector((state) => state.favorite);
  const colorMode = useSelector((state) => state.theme.mode);
  const [requireModal, setRequireModal] = useState(false);
  const [zpidBySearchInput, setZpidBySearchInput] = useState(null);
  const [priceOpen, setPriceOpen] = useState(false);
  const [bedRoomOpen, setBedRoomOpen] = useState(false);
  const [bedRoomExactMatch, setBedRoomExactMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(40);
  const [polygonCoords, setPolygonCoords] = useState([]);
  const [searchObject, setSearchObject] = useState({
    location: "",
    status_type: "ForSale",
    home_type: "Houses",
    minPrice: 0,
    maxPrice: "any price",
    rentMinPrice: 0,
    rentMaxPrice: "any price",
    coordinates: "-87.63411696680458 41.876182344728086,50",
    sort: "",
  });
  const [homeData, setHomeData] = useState([]);
  const [coordinates, setCoordinates] = useState({
    lat: 41.876182344728086,
    lng: -87.63411696680458,
  });
  const [drawing, setDrawing] = useState(false);
  const userInfo = SessionService.getUserData();

  useEffect(() => {
    if (searchObject.coordinates) {
      handleSearch();
    }

    if (window.localStorage.getItem("notShowAgain") === "true") {
      setSuggestOpen(false);
    } else {
      setTimeout(() => {
        setSuggestOpen(true);
      }, 30000);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (zpidBySearchInput) {
      getIndividualProperty();
    }
  }, [zpidBySearchInput]);

  useEffect(() => {
    if (favoriteState.updated === true) {
      saveHomesToUser();
    }
  }, [favoriteState]);

  useEffect(() => {
    if (searchObject.coordinates || searchObject.location) {
      handleSearch();
    }
  }, [searchObject]);

  const saveHomesToUser = () => {
    const userRef = ref(db, "users/" + auth.currentUser.uid);
    let userData = null;
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      userData = data;
    });
    setTimeout(async () => {
      if (userData && !userData.savedHomes) {
        await update(ref(db, "users/" + auth.currentUser.uid), {
          savedHomes: favoriteState.savedHomes,
        });
      } else {
        if (!_.isEqual(userData.savedHomes, favoriteState.savedHomes)) {
          await update(ref(db, "users/" + auth.currentUser.uid), {
            savedHomes: favoriteState.savedHomes,
          });
        }
      }
      dispatch(updateHome(false));
    }, 500);
  };

  const getIndividualProperty = async () => {
    navigate(`/main/property/${zpidBySearchInput}`);
    // const options = {
    //   method: "GET",
    //   url: "https://zillow-com1.p.rapidapi.com/property",
    //   params: {
    //     zpid: zpidBySearchInput,
    //   },
    //   headers: {
    //     "X-RapidAPI-Key": "8ed83f5685msh07680ee9a434eb3p1cc0f6jsnf0f2f90011da",
    //     "X-RapidAPI-Host": "zillow-com1.p.rapidapi.com",
    //   },
    // };

    // try {
    //   const response = await axios.request(options);
    //   if (response.data) {
    //     setHomeData([response.data]);
    //     setTotal(1);
    //     // setPageSize(response.data.resultsPerPage);
    //     setCurrentPage(1);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };
  const handleSearch = async (currentPage = 0) => {
    setLoading(true);
    let options = {
      method: "GET",
      url: "https://zillow-com1.p.rapidapi.com/propertyExtendedSearch",
      params: {
        status_type: searchObject.status_type,
        home_type: "Houses",
        isAuction: false,
        sort:
          searchObject.status_type === "ForSale"
            ? "Homes_for_You"
            : "Verified_Source",
      },
      headers: {
        "X-RapidAPI-Key": "8ed83f5685msh07680ee9a434eb3p1cc0f6jsnf0f2f90011da",
        "X-RapidAPI-Host": "zillow-com1.p.rapidapi.com",
      },
    };

    if (currentPage > 0) {
      options.params.page = currentPage;
    }

    if (searchObject.location && polygonCoords.length === 0) {
      options.params.location = searchObject.location;
    }

    if (searchObject.coordinates && polygonCoords.length === 0) {
      options.params.coordinates = searchObject.coordinates;
    }
    if (polygonCoords.length > 0) {
      let polygonStr = "";
      polygonStr += polygonCoords.map((polygon) => {
        return `${polygon.lng} ${polygon.lat}`;
      });
      polygonStr += `,${polygonCoords[0].lng} ${polygonCoords[0].lat}`;
      options.params.polygon = polygonStr;
    }

    if (searchObject.status_type === "ForSale") {
      if (searchObject.minPrice && searchObject.minPrice !== 0) {
        options.params.minPrice = searchObject.minPrice;
      }
      if (searchObject.maxPrice && searchObject.maxPrice !== "any price") {
        options.params.maxPrice = searchObject.maxPrice;
      }
    }
    if (searchObject.status_type === "ForRent") {
      if (searchObject.rentMinPrice && searchObject.rentMinPrice !== 0) {
        options.params.rentMinPrice = searchObject.rentMinPrice;
      }
      if (
        searchObject.rentMaxPrice &&
        searchObject.rentMaxPrice !== "any price"
      ) {
        options.params.rentMaxPrice = searchObject.rentMaxPrice;
      }
    }

    if (searchObject.bedsMin && searchObject.bedsMin !== "any") {
      options.params.bedsMin = searchObject.bedsMin;
    }

    if (searchObject.bedsMax && searchObject.bedsMax !== "any") {
      options.params.bedsMax = searchObject.bedsMax;
    }

    if (searchObject.bathsMin) {
      options.params.bathsMin = searchObject.bathsMin;
    }

    if (searchObject.bathsMax) {
      options.params.bathsMax = searchObject.bathsMax;
    }

    if (searchObject.sort) {
      options.params.sort = searchObject.sort;
    }

    try {
      const response = await axios.request(options);
      setHomeData([]);
      if (response.data.props && response.data.props.length > 0) {
        setHomeData(response.data.props);
        setTotal(response.data.totalResultCount);
        setPageSize(response.data.resultsPerPage);
        setCurrentPage(response.data.currentPage);
      }
      if (!response.data.props && response.data.zpid) {
        setZpidBySearchInput(response.data.zpid);
      }
      if (response.data.totalResultCount === 0) {
        setHomeData([]);
        setTotal(0);
        setPageSize(5);
        setCurrentPage(1);
      }
      if (!response.data.totalResultCount) {
        setHomeData([]);
        setTotal(0);
        setPageSize(5);
        setCurrentPage(1);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handlePaginationChange = (current, size) => {
    setCurrentPage(current);
    handleSearch(current);
  };

  const handleBounceChange = (mapProps, map) => {
    const center = map.getCenter();
    setSearchObject({
      ...searchObject,
      location: null,
      coordinates: `${center.lng()} ${center.lat()},50`,
    });
  };

  const handleZoomChanged = (mapProps, map, e) => {
    console.log(mapProps, map, e);
  };
  const handleChangeMode = (value) => {
    setSearchObject({
      ...searchObject,
      status_type: value,
    });
    setHomeData([]);
  };

  const handleChangeSort = (value) => {
    setSearchObject({
      ...searchObject,
      sort: value,
    });
  };

  const handleMinPriceChange = (minValue) => {
    if (searchObject.status_type === "ForSale") {
      setSearchObject({
        ...searchObject,
        minPrice: minValue,
      });
    } else {
      setSearchObject({
        ...searchObject,
        rentMinPrice: minValue,
      });
    }
  };
  const handleMaxPriceChange = (maxValue) => {
    if (searchObject.status_type === "ForSale") {
      setSearchObject({
        ...searchObject,
        maxPrice: maxValue,
      });
    } else {
      setSearchObject({
        ...searchObject,
        rentMaxPrice: maxValue,
      });
    }
  };

  const handlePriceOpenChange = (newOpen) => {
    setPriceOpen(newOpen);
  };
  const handleBedRoomOpen = (newOpen) => {
    setBedRoomOpen(newOpen);
  };
  const handleChangeBedRoom = (value) => {
    if (bedRoomExactMatch) {
      setSearchObject({
        ...searchObject,
        bedsMin: value,
        bedsMax: value,
      });
    } else {
      setSearchObject({
        ...searchObject,
        bedsMin: value,
      });
    }
  };
  const handleBedRoomExactMatch = (e) => {
    setBedRoomExactMatch(e.target.checked);
  };
  const handleChangeBathRoom = (value) => {
    setSearchObject({
      ...searchObject,
      bathsMin: value,
      bathsMax: value,
    });
  };

  const handleFavorite = (home, e = null) => {
    if (e) {
      e.stopPropagation();
    }

    if (!authState.isAuthenticated) {
      sessionStorage.setItem("previous_path", window.location.pathname);
      showAuthModal(true);
      return;
    }

    if (favoriteState.savedHomes.length > 0) {
      if (!favoriteState.savedHomes.includes(home)) {
        dispatch(saveHome(home));
      } else {
        dispatch(removeHome(home));
      }
    } else {
      dispatch(saveHome(home));
    }
  };

  const showIcon = (home) => {
    if (!authState.isAuthenticated) {
      return <HeartOutlined />;
    }
    if (favoriteState.savedHomes && favoriteState.savedHomes.length > 0) {
      if (favoriteState.savedHomes.includes(home.zpid)) {
        return <HeartFilled />;
      } else {
        return <HeartOutlined />;
      }
    }

    return <HeartOutlined />;
  };

  const handleSelect = (value) => {
    // When user select a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    if (value) {
      setValue(value, false);
      getGeocode({ address: value }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setCoordinates({
          lat,
          lng,
        });
        setPolygonCoords([]);
        setDrawing(false);
        setSearchObject({
          ...searchObject,
          location: value,
          coordinates: null,
          // coordinates: `${lng} ${lat},2`,
        });
        clearSuggestions();
      });
    }
  };

  const renderSuggestions = () => {
    const suggestions = data.map(({ place_id, description }) => ({
      value: description,
      label: description,
    }));
    return suggestions;
  };

  const handleInput = (value) => {
    setValue(value);
  };

  // const handleClosePropertyModal = () => {
  //   setOpenPropertyModal(false);
  // };

  const handleMapClick = (mapProps, map, clickEvent) => {
    if (drawing) {
      const { latLng } = clickEvent;
      const newCoords = [
        ...polygonCoords,
        { lat: latLng.lat(), lng: latLng.lng() },
      ];
      setPolygonCoords(newCoords);
    }
  };

  const handleSwitchDrawPolygon = (checked) => {
    setDrawing(checked);
    if (checked) {
      setPolygonCoords([]);
      if (window.localStorage.getItem("notShowVideo") === "true") {
        setVideoOpen(false);
      } else {
        setVideoOpen(true);
      }
    } else {
      handleSearch();
    }
  };

  const handleSuggestModal = () => {
    setSuggestOpen(false);
    // setTimeout(() => {
    //   setIntroEnabled(true);
    // }, 5000);
  };

  const handleVideoShowModal = () => {
    setVideoOpen(false);
  };

  const handleExistIntro = () => {
    setIntroEnabled(false);
    handleSearch();
  };

  const handleIntroBeforeChange = (nextStepIndex) => {
    if (nextStepIndex === 0) {
      setDrawing(false);
    }
  };

  const handleIntroAfterChange = (newStepIndex) => {
    if (newStepIndex === 1) {
      setDrawing(true);
    }
    if (newStepIndex === 3) {
      setDrawing(false);
    }
  };

  const getAvailableMinPrice = (items) => {
    let customItems = [...items];
    customItems = customItems.map((item) => {
      if (searchObject.status_type === "ForSale") {
        return {
          ...item,
          disabled: item.value > searchObject.maxPrice,
        };
      } else {
        return {
          ...item,
          disabled: item.value > searchObject.rentMaxPrice,
        };
      }
    });
    return customItems;
  };

  const getAvailableMaxPrice = (items) => {
    let customItems = [...items];
    customItems = customItems.map((item) => {
      if (searchObject.status_type === "ForSale") {
        return {
          ...item,
          disabled: item.value < searchObject.minPrice,
        };
      } else {
        return {
          ...item,
          disabled: item.value < searchObject.rentMinPrice,
        };
      }
    });
    return customItems;
  };

  const handleMarkerClick = (marker, zpid, isScroll = false) => {
    // setActiveMarker(marker);
    // setShowInfoWindow(true);
    if (isScroll) {
      const targetHome = document.querySelector(`.home-${zpid}`);
      if (targetHome) {
        targetHome.scrollIntoView({ behavior: "smooth" });
        targetHome.style.border = "2px solid red";
        setTimeout(() => {
          targetHome.style.border = "none";
        }, 2000);
      }
    }
  };

  const handleMouseEnter = (e, property) => {
    setHoveringHome(property);
    setShowInfoWindow(true);
  };

  const handleMouseLeave = (e) => {
    setHoveringHome(null);
    setShowInfoWindow(false);
  };

  return (
    <div className="page-container full-width">
      {/* <AppHeader isHomePage={false} /> */}
      <HeaderMay2024 isMobile={isMobile} />
      <Steps
        enabled={introEnabled}
        steps={introSteps}
        initialStep={0}
        onExit={handleExistIntro}
        onBeforeChange={handleIntroBeforeChange}
        onAfterChange={handleIntroAfterChange}
        options={{
          showStepNumbers: true,
        }}
      />
      <SearchSectionWrapper colorMode={colorMode} className="min-h-screen">
        <MapWrapper className="map-wrapper">
          <SearchInputs>
            <SearchPanel>
              <SearchAddress colorMode={colorMode}>
                <SearchOutlined
                  style={{
                    color:
                      colorMode === "dark" ? "#ffffff" : "rgba(30, 30, 30, 1",
                  }}
                />
                <Select
                  allowClear={true}
                  onSearch={handleInput}
                  onChange={handleSelect}
                  optionLabelProp="label"
                  options={renderSuggestions()}
                  notFoundContent={null}
                  showSearch
                  filterOption={false}
                  suffixIcon={null}
                  placeholder="Your new house..."
                />
              </SearchAddress>
              <Popover
                content={
                  <Space direction="vertical">
                    <Space>
                      <Space direction="vertical">
                        <Text>Bedrooms</Text>
                        <Space style={{ marginBottom: 16 }}>
                          {bedRoomExactMatch
                            ? BED_ROOMS_EXACT.map((item) => (
                                <Button
                                  key={item.value}
                                  type={
                                    searchObject.bedsMax === item.value
                                      ? "primary"
                                      : "default"
                                  }
                                  onClick={() =>
                                    handleChangeBedRoom(item.value)
                                  }
                                >
                                  {item.label}
                                </Button>
                              ))
                            : BED_ROOMS.map((item) => (
                                <Button
                                  key={item.value}
                                  type={
                                    searchObject.bedsMin === item.value
                                      ? "primary"
                                      : "default"
                                  }
                                  onClick={() =>
                                    handleChangeBedRoom(item.value)
                                  }
                                >
                                  {item.label}
                                </Button>
                              ))}
                        </Space>
                        <Text>Bathrooms</Text>
                        <Space>
                          {BATH_ROOMS.map((item) => (
                            <Button
                              key={item.value}
                              type={
                                searchObject.bathsMin === item.value
                                  ? "primary"
                                  : "default"
                              }
                              onClick={() => handleChangeBathRoom(item.value)}
                            >
                              {item.label}
                            </Button>
                          ))}
                        </Space>
                      </Space>
                    </Space>
                  </Space>
                }
                trigger="click"
                open={bedRoomOpen}
                onOpenChange={handleBedRoomOpen}
              >
                <SearchPopupButton type="text" colorMode={colorMode}>
                  Beds & Baths <DownOutlined />
                </SearchPopupButton>
              </Popover>
              <Select
                className="custom-select"
                value={searchObject.status_type}
                onChange={handleChangeMode}
                options={[
                  { value: "ForSale", label: "For Sale" },
                  { value: "ForRent", label: "For Rent" },
                ]}
                style={{ width: 108, height: "100%" }}
              />
              <Popover
                content={
                  <Space direction="vertical" mode={colorMode}>
                    <Space>
                      <Space direction="vertical">
                        <Text>Min Price:</Text>
                        <Select
                          style={{ width: 120 }}
                          value={
                            searchObject.status_type === "ForSale"
                              ? searchObject.minPrice === 0
                                ? null
                                : searchObject.minPrice
                              : searchObject.rentMinPrice === 0
                              ? null
                              : searchObject.rentMinPrice
                          }
                          placeholder="No Min"
                          onChange={handleMinPriceChange}
                          options={
                            searchObject.status_type === "ForSale"
                              ? getAvailableMinPrice(SALES_MIN_PRICES)
                              : getAvailableMinPrice(RENT_MIN_PRICES)
                          }
                        />
                      </Space>
                      <Space direction="vertical">
                        <Text>Max Price:</Text>
                        <Select
                          style={{ width: 120 }}
                          placeholder="No Max"
                          value={
                            searchObject.status_type === "ForSale"
                              ? searchObject.maxPrice === "any price"
                                ? null
                                : searchObject.maxPrice
                              : searchObject.rentMaxPrice === "any price"
                              ? null
                              : searchObject.rentMaxPrice
                          }
                          onChange={handleMaxPriceChange}
                          options={
                            searchObject.status_type === "ForSale"
                              ? getAvailableMaxPrice(SALES_MAX_PRICES)
                              : getAvailableMaxPrice(RENT_MAX_PRICES)
                          }
                        />
                      </Space>
                    </Space>
                  </Space>
                }
                trigger="click"
                open={priceOpen}
                onOpenChange={handlePriceOpenChange}
              >
                <SearchPopupButton type="text" colorMode={colorMode}>
                  Price <DownOutlined />
                </SearchPopupButton>
              </Popover>
            </SearchPanel>
          </SearchInputs>
          <ClearPolygon>
            {polygonCoords.length > 0 && (
              <Button
                onClick={() => {
                  setPolygonCoords([]);
                  setDrawing(false);
                }}
              >
                Clear
              </Button>
            )}

            <StyledDrawingSwitch
              checked={drawing}
              onChange={handleSwitchDrawPolygon}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </ClearPolygon>
          <Map
            google={google}
            initialCenter={coordinates}
            zoom={10}
            center={coordinates}
            // onZoomChanged={handleZoomChanged}
            onDragend={handleBounceChange}
            onClick={handleMapClick}
          >
            <Polygon paths={polygonCoords} />
            {homeData.length > 0 &&
              homeData.map((item, index) => (
                <Marker
                  onClick={(props, marker, e) =>
                    handleMarkerClick(marker, item.zpid, true)
                  }
                  // onMouseover={(props, marker, e) => {
                  //   handleMarkerClick(marker, item.zpid, false);
                  // }}
                  price={item.price}
                  name={item.address}
                  key={index}
                  position={{ lat: item.latitude, lng: item.longitude }}
                  icon={{
                    url: "https://res.cloudinary.com/dtv3ui485/image/upload/v1685957047/1-min_fqnbti.png",
                    anchor: new google.maps.Point(24, 24),
                    scaledSize: new google.maps.Size(24, 24),
                  }}
                ></Marker>
              ))}
            {/* <InfoWindow visible={showInfoWindow} marker={activeMarker}>
              <div>{activeMarker?.name}</div>
              <div>{activeMarker?.price}</div>
            </InfoWindow> */}
          </Map>
        </MapWrapper>
        <SearchResultWrapper>
          <SearchResults colorMode={colorMode}>
            <SearchTopWrapper>
              <Title level={5} style={{ margin: 0 }}>
                {homeData.length}
                {homeData.length > pageSize
                  ? "+ results"
                  : homeData.length < 1
                  ? " result"
                  : " results"}
              </Title>
              {homeData.length > 0 && (
                <Pagination
                  total={total}
                  pageSize={pageSize}
                  onChange={handlePaginationChange}
                  current={currentPage}
                  showSizeChanger={false}
                />
              )}
              <StyledSortSelect
                className="custom-select"
                colorMode={colorMode}
                onChange={handleChangeSort}
                defaultValue={
                  searchObject.status_type === "For_Sale"
                    ? "Homes_for_You"
                    : "Verified_Source"
                }
                options={
                  searchObject.status_type === "For_Sale"
                    ? saleSortOptions
                    : rentSortOptions
                }
              />
            </SearchTopWrapper>
            {loading && (
              <SpinWrapper>
                <Spin size="large" />
              </SpinWrapper>
            )}
            {homeData.length === 0 && (
              <div className="">
                <Title level={3}>No matching results</Title>
                <Text>Try changing your search.</Text>
              </div>
            )}
            {homeData.length > 0 && (
              <Row gutter={[50, 28]} className="overflow-scroll max-h-screen">
                {homeData.map((home) => (
                  <Col xs={24} sm={12} md={8} lg={24} xl={12} key={home.zpid}>
                    <PropertyCard
                      property={home}
                      colorMode={colorMode}
                      handleFavorite={handleFavorite}
                      showIcon={showIcon}
                    />
                  </Col>
                ))}
              </Row>
            )}
            {/* {homeData.length > 0 && (
              <Pagination
                style={{ marginTop: 10 }}
                total={total}
                pageSize={pageSize}
                onChange={handlePaginationChange}
                current={currentPage}
                showSizeChanger={false}
              />
            )} */}
          </SearchResults>
        </SearchResultWrapper>
      </SearchSectionWrapper>
      {/* <PropertyModal
        open={openPropertyModal}
        onCancel={handleClosePropertyModal}
        zpid={currentProperty}
      /> */}
      <Modal
        open={isAskingLoginModal}
        title="Do you want to save properties?"
        onOk={handleShowAuthModal}
        onCancel={handleHideAuthModal}
      >
        In order to save properties please log in using your email.
      </Modal>
      <SuggestModal isOpen={suggestOpen} onCancel={handleSuggestModal} />
      <VideoPlayModal isOpen={videoOpen} onCancel={handleVideoShowModal} />
    </div>
  );
};

export const CustomSearch = GoogleApiWrapper({
  apiKey: "AIzaSyAR6QrMzFPNywR0i92blHVmKQjNVOT1990",
})(Search);
