import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import { PropertyModal } from "./PropertyModal";
import { ReactComponent as BedIcon } from "../../assets/images/bed.svg";
import { ReactComponent as BathIcon } from "../../assets/images/bath.svg";

import {
  StyledPropertyCard,
  FavoriteButton,
  PropertyImage,
  InfoWrapper,
  InfoContainer,
  TitleText,
  DescriptionText,
  Price,
  RoomsInfoWrapper,
  RoomInfo,
  ButtonWrapper,
  StyledButton,
} from "./styles";

const PropertiesCarousel = ({
  data,
  colorMode,
  handleFavorite,
  isSavedProperty,
}) => {
  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [currentProperty, setCurrentProperty] = useState(null);

  useEffect(() => {
    setOpenPropertyModal(!!currentProperty);
  }, [currentProperty]);

  const handleClosePropertyModal = () => {
    setCurrentProperty(null);
  };

  const handleCheckProperty = (item) => {
    setCurrentProperty(item);
  };

  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={24}
        navigation={true}
        modules={[Navigation]}
      >
        {data?.map((item, index) => {
          const isSaved = isSavedProperty(item);
          return (
            <SwiperSlide key={index}>
              <StyledPropertyCard colorMode={colorMode}>
                <FavoriteButton>
                  <Button
                    shape="circle"
                    onClick={(e) => (isSaved ? null : handleFavorite(e, item))}
                    icon={isSaved ? <HeartFilled /> : <HeartOutlined />}
                  />
                </FavoriteButton>
                <PropertyImage src={item.photo_url} alt="property Image" />
                <InfoWrapper>
                  <TitleText colorMode={colorMode}>{item.title}</TitleText>
                  <DescriptionText colorMode={colorMode}>
                    {item.description?.length > 100
                      ? `${item.description.substr(0, 100)}...`
                      : item.description}
                  </DescriptionText>
                  <InfoContainer>
                    <RoomsInfoWrapper>
                      <RoomInfo colorMode={colorMode}>
                        {item.beds} <BedIcon />
                      </RoomInfo>
                      <RoomInfo colorMode={colorMode}>
                        {item.baths} <BathIcon />
                      </RoomInfo>
                    </RoomsInfoWrapper>
                    <Price colorMode={colorMode}>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(item.price)}
                    </Price>
                  </InfoContainer>
                </InfoWrapper>
                <ButtonWrapper>
                  <StyledButton onClick={() => handleCheckProperty(item)}>
                    View More
                  </StyledButton>
                </ButtonWrapper>
              </StyledPropertyCard>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <PropertyModal
        colorMode={colorMode}
        open={openPropertyModal}
        data={currentProperty}
        onCancel={handleClosePropertyModal}
      />
    </>
  );
};

export default PropertiesCarousel;
