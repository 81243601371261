import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../services/ApiService";

const initialState = {
  isLoading: false,
  paymentInfo: {},
};

const isLoading = (action) => {
  return [
    "stripe/create-subscription/pending",
  ].includes(action.type);
};

const isFinishLoading = (action) => {
  return [
    "stripe/create-subscription/fulfilled",
    "stripe/create-subscription/rejected",
  ].includes(action.type);
};

const isRejected = (action) => {
  return [
    "stripe/create-subscription/rejected",
  ].includes(action.type);
};

export const createSubscription = createAsyncThunk(
  "stripe/create-subscription",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`stripe/create-subscription`, values);
      return data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  }
);

export const clearSubscriptionInfo = createAsyncThunk(
  "stripe/clear-info",
  async (values, { rejectWithValue }) => {
    try {
      return values;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  }
);

export const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.paymentInfo = action.payload;
      })
      .addCase(clearSubscriptionInfo.fulfilled, (state, action) => {
        state.paymentInfo = {};
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default stripeSlice.reducer;
