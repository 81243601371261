import styled from "styled-components";

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  justify-content: flex-start;
  padding: 8px;
  .result-home {
    min-height: 290px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
    position: relative;

    &-favorite {
      position: absolute;
      right: 10px;
      top: 10px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-image {
      width: 100%;
      img {
        width: 100%;
      }
    }

    &-info {
      padding: 8px;
      &-bottom {
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &-top {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        &-price {
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 8px;
        }

        &-rooms {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 10px;
        }

        &-bed {
          margin-right: 8px;
        }

        &-bed,
        &-bath {
          font-size: 20px;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const SpinWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;
