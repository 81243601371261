import { Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BedIcon } from "../../assets/images/bed.svg";
import { ReactComponent as BathIcon } from "../../assets/images/bath.svg";
import { PropertyContainer } from "./styles";

const { Text } = Typography;

const PropertyCard = ({ colorMode, property, handleFavorite, showIcon }) => {
  const navigate = useNavigate();

  const handleCheckProperty = (zpid) => {
    navigate(`/main/property/${zpid}`);
  };
  return (
    <PropertyContainer
      colorMode={colorMode}
      onClick={() => handleCheckProperty(property.zpid)}
    >
      <div className="property-card-favorite">
        <Button
          shape="circle"
          onClick={(e) => handleFavorite(property.zpid, e)}
          icon={showIcon(property)}
          // disabled={!authState.isAuthenticated}
        />
      </div>
      <div className="property-card-image">
        <img src={property.imgSrc} alt="" />
      </div>
      <div className="property-card-info">
        <Text className="property-card-info-top-price">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(property.price)}
        </Text>
        <div className="property-card-info-bottom">
          <div className="property-card-info-bottom-rooms">
            <Text className="property-card-info-bottom-rooms-item">
              {property.bedrooms} <BedIcon />
            </Text>
            <Text className="property-card-info-bottom-rooms-item">
              {property.bathrooms} <BathIcon />
            </Text>
          </div>
          <Text>
            {property.address?.streetAddress
              ? `${property.address.streetAddress} ${property.address.city} ${property.address.state} ${property.address.zipcode}`
              : property.address}
          </Text>
        </div>
      </div>
    </PropertyContainer>
  );
};

export default PropertyCard;
