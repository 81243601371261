import {
  Badge,
  Button,
  Card,
  Col,
  Popover,
  Row,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import _ from "lodash";

import {
  CustomTitle,
  InteriorValue,
  OverviewDetailsWrapper,
  OverviewWrapper,
  PropertyDetails,
  PropertyValueWrapper,
  SaveCountsWrapper,
  SchoolInfo,
  SchoolLink,
  SchoolRate,
  SchoolWrapper,
  ShowMoreWrapper,
  SpinWrapper,
  SwiperWrapper,
} from "./styles";
import { HeartFilled, HeartOutlined, HolderOutlined } from "@ant-design/icons";
import { priceColumns, taxColumns } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { removeHome, saveHome, updateHome } from "../../store/favorite";
import { db, auth } from "../../plugins/firebase";
import { onValue, ref, update } from "firebase/database";

const { Title, Text } = Typography;

export const Property = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [property, setProperty] = useState(null);
  const authState = useSelector((state) => state.auth);
  const favoriteState = useSelector((state) => state.favorite);
  const [images, setImages] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (favoriteState.updated) {
      saveHomesToUser();
    }
  }, [favoriteState]);

  useEffect(() => {
    if (params.zpid) {
      loadProperty();
      setCurrentTab(1);
      setTimeout(() => {
        loadPropertyImages();
      }, 1000);
    }
  }, [params]);

  const saveHomesToUser = async () => {
    const userRef = ref(db, "users/" + auth.currentUser.uid);
    let userData = null;
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      userData = data;
    });
    if (!userData.savedHomes) {
      await update(ref(db, "users/" + auth.currentUser.uid), {
        savedHomes: favoriteState.savedHomes,
      });
    } else {
      if (!_.isEqual(userData.savedHomes, favoriteState.savedHomes)) {
        await update(ref(db, "users/" + auth.currentUser.uid), {
          savedHomes: favoriteState.savedHomes,
        });
      }
    }
    dispatch(updateHome(false));
  };

  const loadProperty = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      url: "https://zillow-com1.p.rapidapi.com/property",
      params: {
        zpid: params.zpid,
      },
      headers: {
        "X-RapidAPI-Key": "8ed83f5685msh07680ee9a434eb3p1cc0f6jsnf0f2f90011da",
        "X-RapidAPI-Host": "zillow-com1.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);
      if (response.data) {
        setProperty(response.data);

        console.log(response.data)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showIcon = (home) => {
    if (!authState.isAuthenticated) {
      return <HeartOutlined />;
    }
    if (favoriteState.savedHomes.length > 0) {
      if (favoriteState.savedHomes.includes(home.toString())) {
        return <HeartFilled />;
      } else {
        return <HeartOutlined />;
      }
    }

    return <HeartOutlined />;
  };

  const handleFavorite = (home, e = null) => {
    let customHome = home.toString();
    if (e) {
      e.stopPropagation();
    }

    if (favoriteState.savedHomes.length > 0) {
      if (!favoriteState.savedHomes.includes(customHome)) {
        dispatch(saveHome(customHome));
      } else {
        dispatch(removeHome(customHome));
      }
    } else {
      dispatch(saveHome(customHome));
    }
  };

  const loadPropertyImages = async () => {
    const options = {
      method: "GET",
      url: "https://zillow-com1.p.rapidapi.com/images",
      params: { zpid: params.zpid },
      headers: {
        "X-RapidAPI-Key": "8ed83f5685msh07680ee9a434eb3p1cc0f6jsnf0f2f90011da",
        "X-RapidAPI-Host": "zillow-com1.p.rapidapi.com",
      },
    };
    try {
      const response = await axios.request(options);
      setImages(response.data.images);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const getGeneralInfo = () => {
    if (!property) {
      return null;
    }
    return (
      <OverviewDetailsWrapper>
        <OverviewWrapper>
          <div className="overview-left">
            <PropertyValueWrapper>
              <Badge status="error" />
              <Title level={5} style={{ margin: 0 }}>
                {property.homeStatus}
              </Title>
            </PropertyValueWrapper>
            {property.resoFacts.atAGlanceFacts.length > 0 &&
              property.resoFacts.atAGlanceFacts.map((fact, index) => (
                <PropertyValueWrapper key={index}>
                  <HolderOutlined style={{ fontSize: 20 }} />
                  <Title level={5} style={{ margin: 0 }}>
                    {fact.factLabel}:
                  </Title>
                  <Title level={5} style={{ margin: 0 }}>
                    {fact.factValue || "No Data"}
                  </Title>
                </PropertyValueWrapper>
              ))}
            {property.resoFacts.rooms &&
              property.resoFacts.rooms.length > 0 &&
              property.resoFacts.rooms.map((room, index) => {
                if (!room.roomType || !room.roomLevel || !room.roomArea) {
                  return;
                }
                return (
                  <div key={index}>
                    <Title level={5} style={{ margin: 0 }}>
                      {room.roomType}
                    </Title>
                    <ul>
                      <li>
                        <PropertyValueWrapper>
                          <Title level={5} style={{ margin: 0 }}>
                            Level:
                          </Title>
                          <Text>{room.roomLevel}</Text>
                        </PropertyValueWrapper>
                      </li>
                      <li>
                        <PropertyValueWrapper>
                          <Title level={5} style={{ margin: 0 }}>
                            Area:
                          </Title>
                          <Text>
                            {room.roomArea} {room.roomAreaUnits}
                          </Text>
                        </PropertyValueWrapper>
                      </li>
                      <li>
                        <PropertyValueWrapper>
                          <Title level={5} style={{ margin: 0 }}>
                            Dimensions:
                          </Title>
                          <Text>{room.roomDimensions}</Text>
                        </PropertyValueWrapper>
                      </li>
                    </ul>
                  </div>
                );
              })}
          </div>
          <div className="overview-right">
            <Title level={4} style={{ marginTop: 0 }}>
              Interior details
            </Title>
            <Title level={5} style={{ marginTop: 0 }}>
              Bedrooms and bathrooms
            </Title>
            <ul>
              <li>
                <PropertyValueWrapper>
                  <Title level={5} style={{ margin: 0 }}>
                    Bedrooms:
                  </Title>
                  <Text>{property.resoFacts.bedrooms}</Text>
                </PropertyValueWrapper>
              </li>
              <li>
                <PropertyValueWrapper>
                  <Title level={5} style={{ margin: 0 }}>
                    Bathrooms:
                  </Title>
                  <Text>{property.resoFacts.bathrooms}</Text>
                </PropertyValueWrapper>
              </li>

              {property.resoFacts.bathroomsFull && (
                <li>
                  <PropertyValueWrapper>
                    <Title level={5} style={{ margin: 0 }}>
                      Full bathrooms:
                    </Title>
                    <Text>{property.resoFacts.bathroomsFull}</Text>
                  </PropertyValueWrapper>
                </li>
              )}
              {property.resoFacts.bathroomsHalf > 0 && (
                <li>
                  <PropertyValueWrapper>
                    <Title level={5} style={{ margin: 0 }}>
                      1/2 bathrooms:
                    </Title>
                    <Text>{property.resoFacts.bathroomsHalf}</Text>
                  </PropertyValueWrapper>
                </li>
              )}
              {property.resoFacts.bathroomsOneQuarter > 0 && (
                <li>
                  <PropertyValueWrapper>
                    <Title level={5} style={{ margin: 0 }}>
                      1/4 bathrooms:
                    </Title>
                    <Text>{property.resoFacts.bathroomsOneQuarter}</Text>
                  </PropertyValueWrapper>
                </li>
              )}
              {property.resoFacts.bathroomsThreeQuarter > 0 && (
                <li>
                  <PropertyValueWrapper>
                    <Title level={5} style={{ margin: 0 }}>
                      3/4 bathrooms:
                    </Title>
                    <Text>{property.resoFacts.bathroomsThreeQuarter}</Text>
                  </PropertyValueWrapper>
                </li>
              )}
            </ul>

            <Title level={5} style={{ marginTop: 0 }}>
              Basement
            </Title>
            <ul>
              <li>
                <PropertyValueWrapper>
                  <Title level={5} style={{ margin: 0 }}>
                    Basement:
                  </Title>
                  <Text>{property.resoFacts.basement}</Text>
                </PropertyValueWrapper>
              </li>
            </ul>
            {property.resoFacts.flooring &&
              property.resoFacts.flooring.length > 0 && (
                <li>
                  <PropertyValueWrapper>
                    <InteriorValue>
                      <Title level={5} style={{ margin: 0 }}>
                        Flooring:
                      </Title>
                      {property.resoFacts.flooring.map((item) => (
                        <div key={item}>{item}</div>
                      ))}
                    </InteriorValue>
                  </PropertyValueWrapper>
                </li>
              )}
            <ul>
              {property.resoFacts.interiorFeatures &&
                property.resoFacts.windowFeatures &&
                property.resoFacts.windowFeatures.length > 0 && (
                  <li>
                    <InteriorValue>
                      <Title level={5} style={{ margin: 0 }}>
                        Window features:
                      </Title>
                      {property.resoFacts.windowFeatures.map((item) => (
                        <div key={item}>{item}</div>
                      ))}
                    </InteriorValue>
                  </li>
                )}
              {property.resoFacts.interiorFeatures &&
                property.resoFacts.interiorFeatures.length > 0 && (
                  <li>
                    <InteriorValue>
                      <Title level={5} style={{ margin: 0 }}>
                        Interior features:
                      </Title>
                      {property.resoFacts.interiorFeatures.map((item) => (
                        <div key={item}>{item}</div>
                      ))}
                    </InteriorValue>
                  </li>
                )}
            </ul>
            {property.resoFacts.appliances &&
              property.resoFacts.appliances &&
              property.resoFacts.appliances.length > 0 && (
                <Title level={5} style={{ marginTop: 0 }}>
                  Appliances
                </Title>
              )}
            <ul>
              {property.resoFacts.appliances &&
                property.resoFacts.appliances.length > 0 && (
                  <li>
                    <InteriorValue>
                      <Title level={5} style={{ margin: 0 }}>
                        Appliances included:
                      </Title>
                      {property.resoFacts.appliances.map((item) => (
                        <div key={item}>{item}</div>
                      ))}
                    </InteriorValue>
                  </li>
                )}
              {property.resoFacts.laundryFeatures &&
                property.resoFacts.laundryFeatures.length > 0 && (
                  <li>
                    <InteriorValue>
                      <Title level={5} style={{ margin: 0 }}>
                        Laundry features:
                      </Title>
                      {property.resoFacts.laundryFeatures.map((item) => (
                        <div key={item}>{item}</div>
                      ))}
                    </InteriorValue>
                  </li>
                )}
            </ul>

            <Title level={4} style={{ marginTop: 0 }}>
              Parking
            </Title>
            <PropertyValueWrapper>
              <Title level={5} style={{ margin: 0 }}>
                Total places:
              </Title>
              <Text>{property.resoFacts.parking}</Text>
            </PropertyValueWrapper>
            <Title level={4} style={{ marginTop: 0 }}>
              Listing courtesy of:
            </Title>
            <PropertyValueWrapper>
              <Text>{property.attributionInfo.agentName}</Text>
              <Text>{property.attributionInfo.agentPhoneNumber}</Text>
            </PropertyValueWrapper>
            <Text>{property.attributionInfo.brokerName}</Text>
            <PropertyValueWrapper>
              <Title level={5} style={{ margin: 0 }}>
                Source:
              </Title>
              <Text>{property.attributionInfo.infoString2}</Text>
            </PropertyValueWrapper>
            <PropertyValueWrapper>
              <Title level={5} style={{ margin: 0 }}>
                MLS#:
              </Title>
              <Text>{property.attributionInfo.infoString1}</Text>
              <img
                src={property.attributionInfo.infoString3}
                alt=""
                width="75px"
                height="20px"
              />
            </PropertyValueWrapper>
          </div>
        </OverviewWrapper>
        <div className="property-right">
          {images.length > 0 && (
            <SwiperWrapper>
              <div className="favorite-btn">
                <Button
                  shape="circle"
                  icon={showIcon(property.zpid)}
                  disabled={!authState.isAuthenticated}
                  onClick={() => handleFavorite(property.zpid)}
                />
              </div>
              <Swiper
                slidesPerView={1}
                modules={[Navigation, Pagination]}
                navigation
                pagination={{ clickable: true }}
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img src={image} height="350px" alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperWrapper>
          )}
          <ShowMoreWrapper>
            <CustomTitle
              level={5}
              style={{ marginTop: 0 }}
              size={showMore ? "auto" : "70px"}
            >
              {property.description}
            </CustomTitle>
            <Button
              type="default"
              style={{ marginBottom: 10 }}
              onClick={() => handleShowMore()}
            >
              {showMore ? "Hide" : "Show More"}
            </Button>
          </ShowMoreWrapper>
          <SaveCountsWrapper>
            <Badge.Ribbon text="on Zillow">
              <Card size="small" style={{ width: 180 }}>
                {property.timeOnZillow}
              </Card>
            </Badge.Ribbon>
            <Badge.Ribbon text="views">
              <Popover
                placement="bottom"
                content="Total views in the last 6 days"
              >
                <Card size="small" style={{ width: 140 }}>
                  {property.pageViewCount}
                </Card>
              </Popover>
            </Badge.Ribbon>
            <Badge.Ribbon text="saves">
              <Popover
                placement="bottom"
                content="Total saves in the last 6 days"
              >
                <Card size="small" style={{ width: 140 }}>
                  {property.favoriteCount}
                </Card>
              </Popover>
            </Badge.Ribbon>
          </SaveCountsWrapper>
        </div>
      </OverviewDetailsWrapper>
    );
  };

  const getHistoryInfo = () => {
    if (!property) {
      return null;
    }

    const priceHistoryData =
      property.priceHistory && property.priceHistory.length > 0
        ? property.priceHistory.map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })
        : [];
    const taxHistoryData =
      property.taxHistory && property.taxHistory.length > 0
        ? property.taxHistory.map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })
        : [];
    return (
      <div>
        <Title level={4} style={{ marginTop: 0 }}>
          Price history
        </Title>
        <Table dataSource={priceHistoryData} columns={priceColumns} />
        <Title level={4} style={{ marginTop: 0 }}>
          Public tax history
        </Title>
        <Table dataSource={taxHistoryData} columns={taxColumns} />
      </div>
    );
  };

  const handleCheckProperty = (zpid) => {
    navigate(`/main/property/${zpid}`);
  };

  const getNeighborhoodInfo = () => {
    if (!property || !property.nearbyHomes || property.nearbyHomes.length < 1) {
      return null;
    }

    return (
      <div>
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          {property.nearbyHomes.map((home) => (
            <Col xs={24} sm={12} md={8} lg={8} xl={6} key={home.zpid}>
              <div
                key={home.zpid}
                className="result-home"
                onClick={() => handleCheckProperty(home.zpid)}
              >
                <div className="result-home-image">
                  <img
                    src={home.miniCardPhotos[0].url}
                    width="300px"
                    height="180px"
                    alt=""
                  />
                </div>
                <div className="result-home-info">
                  <div className="result-home-info-top">
                    <Text className="result-home-info-top-price">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(home.price)}
                    </Text>
                  </div>
                  <div className="result-home-info-bottom">
                    <Text>
                      {home.address.streetAddress
                        ? `${home.address.streetAddress} ${home.address.city} ${home.address.state} ${home.address.zipcode}`
                        : home.address}
                    </Text>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {property.schools && property.schools.length > 0 && (
          <>
            <Title level={4}>Nearby schools in Southgate</Title>
            <Title level={5} style={{ marginTop: 0 }}>
              Schools provided by the listing agent
            </Title>
            <Text>
              This data may not be complete. We recommend contacting the local
              school district to confirm school assignments for this home.
            </Text>
            {property.schools.map((school, index) => (
              <SchoolWrapper key={index}>
                <SchoolRate>
                  <span>{school.rating}</span>/10
                </SchoolRate>
                <SchoolInfo>
                  <SchoolLink>
                    <a href={school.link} target="_blank">
                      {school.name}
                    </a>
                  </SchoolLink>
                  <SchoolInfo>
                    Grades: {school.grades} Distance: {school.distance} mi
                  </SchoolInfo>
                </SchoolInfo>
              </SchoolWrapper>
            ))}
          </>
        )}
      </div>
    );
  };

  const tabItems = [
    {
      key: 1,
      label: "General Info",
      children: getGeneralInfo(),
    },
    {
      key: 2,
      label: "Price and tax history",
      children: getHistoryInfo(),
    },
    {
      key: 3,
      label: "Neighborhood details",
      children: getNeighborhoodInfo(),
    },
  ];

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  return (
    <div className="page-container">
      <Title level={1}>Property Details</Title>
      {loading && (
        <SpinWrapper>
          <Spin size="large" />
        </SpinWrapper>
      )}
      {property && (
        <PropertyDetails>
          <PropertyValueWrapper>
            <Title
              level={2}
              style={{ marginTop: 0, fontWeight: "bold", marginRight: 10 }}
            >
              {property.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              })}
            </Title>
            <Title level={4} style={{ marginTop: 0 }}>
              {property.bedrooms} bd | {property.bathrooms} ba |{" "}
              {property.livingArea} {property.livingAreaUnits}
            </Title>
          </PropertyValueWrapper>
          <Title level={4} style={{ marginTop: 0 }}>
            {property.streetAddress} {property.city} {property.zipcode}
          </Title>
          <Tabs
            items={tabItems}
            onChange={handleTabChange}
            activeKey={currentTab}
          />
        </PropertyDetails>
      )}
    </div>
  );
};
