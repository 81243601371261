import styled from "styled-components";

export const PropertyContainer = styled.div`
  height: 262px;
  border-radius: 18.408px;
  background: ${(props) =>
    props.colorMode === "dark" ? "rgba(117, 117, 117, 0.3)" : "#f2f2f2"};
  box-shadow: ${(props) =>
    props.colorMode === "dark"
      ? "0px 0px 0px 0px rgba(0, 0, 0, 0.50), 0px 1px 2px 0px rgba(0, 0, 0, 0.49), 0px 4px 4px 0px rgba(0, 0, 0, 0.43), 0px 10px 6px 0px rgba(0, 0, 0, 0.25), 0px 17px 7px 0px rgba(0, 0, 0, 0.07), 0px 27px 7px 0px rgba(0, 0, 0, 0.01)"
      : "0px 0px 0px 0px rgba(0, 0, 0, 0.30), 0px 0.9751583337783813px 1.9503166675567627px 0px rgba(0, 0, 0, 0.29), 0px 3.9006333351135254px 3.9006333351135254px 0px rgba(0, 0, 0, 0.26), 0px 9.751582145690918px 5.850950241088867px 0px rgba(0, 0, 0, 0.15), 0px 16.57769203186035px 6.826107978820801px 0px rgba(0, 0, 0, 0.04), 0px 26.329273223876953px 6.826107978820801px 0px rgba(0, 0, 0, 0.01)"};
  position: relative;
  overflow: hidden;

  &:hover {
    transform: scale(1.01);
    transition: all 0.2s ease;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 9px 0px;
  }

  .property-card-favorite {
    position: absolute;
    right: 10px;
    top: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .property-card-image {
    width: 100%;
    height: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .property-card-info {
    padding: 16px 12px;
    &-top-price {
      color: ${(props) => (props.colorMode === "dark" ? "#ffffff" : "#1E1E1E")};
      font-size: 20px;
      font-weight: 500;
    }
    &-bottom {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &-rooms {
        margin: 10px 0;
        display: flex;
        align-items: center;
        gap: 24px;
        &-item {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
      & .ant-typography {
        font-size: 16px;
        color: ${(props) => (props.colorMode === "dark" ? "#DDD" : "#5F5F5F")};
      }
    }
  }
`;
