import React from "react";
import StatisticCard from "./StatisticCard";

const Statistics2 = () => {
  return (
    <div className=' text-white '>
      <div className='flex flex-col mb-14'>
        <span className='text-center text-4xl mb-4'>
          The ultimate Real Estate Assistant.
        </span>
        <span className='text-center text-2xl mb-6'>
          Making you more efficient, and your clients happier.
        </span>
      </div>
      <div className='flex flex-col md:flex-row justify-center md:space-x-20 gap-10 md:gap-0'>
        <StatisticCard
          imgSrc='/path/to/efficiency-icon.svg'
          altText='Efficiency'
          statText='Efficiency'
          statNumber={"10x"}
          iconType='speedometer'
        />
        <StatisticCard
          imgSrc='/path/to/happy-clients-icon.svg'
          altText='Happy Clients'
          statText='Happy clients'
          statNumber={"5x"}
          iconType='person'
        />
        <StatisticCard
          imgSrc='/path/to/deal-closed-icon.svg'
          altText='Deals Closed'
          statText='Deals closed'
          statNumber={"3x"}
          iconType='document'
        />
      </div>
    </div>
  );
};

export default Statistics2;
